import React from 'react';
import { DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';

interface AbortedStepProps {
    item: Item;
}

const ReturnPendingStep: React.FC<AbortedStepProps> = ({ item }) => {
    return (
        <StyledStack>
            <DescriptionText>We are currently processing the return of your item to
                address { item.shipBack?.shippingAddress }.</DescriptionText>
        </StyledStack>
    );
};

export default ReturnPendingStep;
