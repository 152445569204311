import { AppBar, styled, Tabs } from '@worthy-npm/worthy-common-ui-components';

const StyledNavToolBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  width: '100%',
  marginTop: '1.5px',
  top: '64.5px',
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    top: '56.5px',
  },
}));

const TabsContainer = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'center',
    width:'980px',
    borderBottom: `1.5px solid ${theme.palette.divider}`,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
      width: '100%',

    },
  },
  flex: 1,

  '.MuiTabs-indicator': {
    width: '176px',
    backgroundColor: theme.palette.highlight.main,
  },

  '.tab-wrapper': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
    textTransform: 'none',
    padding: '16px 42px',
    '&.Mui-selected': {
      color: theme.palette.highlight.main,
      fontWeight: 600,
    },
  },
}));

export { StyledNavToolBar, TabsContainer };
