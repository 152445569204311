import {
    runtimeType,
    runtimeTitle,
    runtimeGtmId,
    gtmAuth,
    gtmEnvId,
    rollbarToken,
    applitoolsApiKey,
    monolithUrl,
    appUrl,
    contentSiteUrl,
    blogUrl,
    authUrl,
    submissionUrl,
    defaultCalendly,
    backendUrl,
    calendlyWatchesUrl,
    calendlyJbUrl,
    calendlyUltraUrl,
    calendlyHvUrl,
    googleApiKey,
    prod, viteAppVersion, calendlyToken,
    calendlyUnverifiedUrl,
} from 'src/config/config.ts';

export const getRuntimeType = (): string => runtimeType;
export const getRuntimeTitle = (): string => runtimeTitle;
export const getRuntimeGtmId = (): string => runtimeGtmId;
export const getGtmAuth = (): string => gtmAuth;
export const getGtmEnvId = (): string => gtmEnvId;
export const getRollbarToken = (): string => rollbarToken;
export const getApplitoolsApiKey = (): string => applitoolsApiKey;
export const getMonolithUrl = (): string => monolithUrl;
export const getBackendUrl = (): string => backendUrl;
export const getAppUrl = (): string => appUrl;
export const getContentSiteUrl = (): string => contentSiteUrl;
export const getBlogUrl = (): string => blogUrl;
export const getAuthUrl = (): string => authUrl;
export const getSubmissionUrl = (): string => submissionUrl;
export const getDefaultCalendly = (): string => defaultCalendly;
export const getCalendlyWatchesUrl = (): string => calendlyWatchesUrl;
export const getCalendlyJbUrl = (): string => calendlyJbUrl;
export const getCalendlyUltraUrl = (): string => calendlyUltraUrl;
export const getCalendlyHvUrl = (): string => calendlyHvUrl;
export const getCalendlyUnverifiedUrl = (): string => calendlyUnverifiedUrl;
export const getGoogleApiKey = (): string => googleApiKey;
export const isProd = (): boolean => prod;
export const appVersion = (): string => viteAppVersion;
export const getCalendlyToken = (): string => calendlyToken;
