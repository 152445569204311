import { useSnackbar } from 'notistack';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import ItemNotification from 'src/components/Notification/ItemNotification';
import {  getContentSiteUrl } from 'src/config';
import { NotificationI, NotificationTypes } from 'src/types/notification';
import _ from 'lodash';
import ItemTypePlaceHolder from 'src/views/ItemTypePlaceHolder';
import { styled } from '@worthy-npm/worthy-common-ui-components';
import { windowOpenTo } from 'src/helpers/common';

const enum NotificationVaraints {
  SUCCESS= 'success',
  ITEM_NOTIFICATION= 'itemNotification',
}

type notificationObjectByTypeResult = {
  variant: NotificationVaraints.SUCCESS,
  title: string,
  message: string,
} | {
  variant: NotificationVaraints.ITEM_NOTIFICATION,
  message: string,
  description: string,
  learnMore?: () => void,
  img: React.ReactNode,
};

const StyledImg = styled('img')(()=>({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const notificationObjectByType = (notification: NotificationI):notificationObjectByTypeResult => {
  if(notification.type === NotificationTypes.ITEM_SUBMITTED) {
    return {
      variant: NotificationVaraints.SUCCESS,
      title: 'Your item successfully submitted!',
      message: 'Now, the next step is to ship it to Worthy offices',
    }
  }
  if(notification.type === NotificationTypes.SHIPMENT_SCHEDULED) {
    return {
      variant: NotificationVaraints.SUCCESS,
      title: `${_.capitalize(notification?.context?.shipmentType)} successfully scheduled!`,
      message: `Make sure to prepare your item for ${notification?.context?.shipmentType}.`,
    }
  }
  if(notification.type === NotificationTypes.ITEM_NOT_ARRIVED) {
    const imgUrl = notification?.context?.item?.photos[0]?.url;
    return {
      variant: NotificationVaraints.ITEM_NOTIFICATION,
      message: `Item ${notification?.context?.item?.itemDescription} didn't arrive.`,
      description: 'We removed this item from your listings.',
      img: imgUrl ? <StyledImg src={imgUrl} alt="item" />: <ItemTypePlaceHolder itemType={notification?.context?.item?.itemType} />,
    }
  }
  if(notification.type === NotificationTypes.ITEM_REJECTED) {
    const imgUrl = notification?.context?.item?.photos[0]?.url;
    return {
      variant: NotificationVaraints.ITEM_NOTIFICATION,
      message: `Unfortunately item ${notification.context.item.itemDescription} is not currently eligible for auction.`,
      description: 'about why this item was removed from your listings.',
      learnMore: () => {
        windowOpenTo({url: `${getContentSiteUrl()}/about/what-we-accept`, target:'_blank'});
      },
      img: imgUrl ? <StyledImg src={imgUrl} alt="item" /> : <ItemTypePlaceHolder itemType={notification?.context?.item?.itemType} />,
    }
  }
  return {
    variant: NotificationVaraints.SUCCESS,
    title: '',
    message: '',
  }
}


const useUINotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = (notification: NotificationI) => {
    const notificationObject = notificationObjectByType(notification);
    if(notificationObject.variant === NotificationVaraints.SUCCESS) {
      enqueueSnackbar('', {
        key: notification.id,
        content: () => (
          <SuccessNotification
            id={notification.id}
            title={notificationObject.title}
            message={notificationObject.message}
            onClose={() => closeSnackbar(notification.id)}
            offline={notification.offline}
            />
          ),
          anchorOrigin:{ horizontal: "center", vertical: "bottom" },
          persist: true,
      });
    }
    if(notificationObject.variant === NotificationVaraints.ITEM_NOTIFICATION) {
      enqueueSnackbar('', {
        key: notification.id,
        content: () => (
          <ItemNotification
            id={notification.id}
            message={notificationObject.message}
            description={notificationObject.description}
            learnMore={notificationObject.learnMore}
            img={notificationObject.img}
            onClose={() => closeSnackbar(notification.id)}
            offline={notification.offline}
          />
        ),
          anchorOrigin:{ horizontal: "center", vertical: "bottom" },
          persist: true,
      });
    }
  }



  return { showNotification };
};

export default useUINotification;
