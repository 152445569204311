import React, { useCallback } from 'react';
import {
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from '@worthy-npm/worthy-common-ui-components';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { FAQWrapper, StackWrapper, StyledFAqAccordion } from 'src/styles/faq/faq.styles.tsx';
import { StyledExpandMoreIcon } from 'src/styles/hiw/hiw.styles.tsx';
export interface Question {
    q: string;
    A: () => React.ReactElement;
}

interface FaqProps  {
    questions: Question[]
};

const FAQ = ({questions}: FaqProps) => {
    const [expanded, setExpanded] = React.useState<number>();

    const handleChange = useCallback(
        (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : undefined);
        },
        [],
    );

    return (
        <FAQWrapper
            component="section"
            data-automation="faq-section"
        >
            <StackWrapper>
                <Typography
                    variant={worthyShineTheme.breakpoints.down('md') ? 'h2Mobile' : 'h2'}
                    color="text.secondary"
                >
                    FAQs
                </Typography>
                <Box>
                    {questions.map((item, idx) => (
                        <StyledFAqAccordion
                            data-automation={`faq-${idx}`}
                            key={idx}
                            expanded={expanded === idx}
                            onChange={handleChange(idx)}
                            color="primary"
                        >
                            <AccordionSummary
                                key={idx}
                                expandIcon={<StyledExpandMoreIcon color='inherit' />}
                            >
                                <Typography variant="h3">{item.q}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    component="span"
                                    variant="body1"
                                >
                                    <item.A />
                                </Typography>
                            </AccordionDetails>
                        </StyledFAqAccordion>
                    ))}
                </Box>
            </StackWrapper>
        </FAQWrapper>
    );
};

export default FAQ;
