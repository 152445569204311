import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Icon
} from '@worthy-npm/worthy-common-ui-components';
import { setOpenScheduling, setSchedulingMainItem } from 'src/store/schedulingSlice';
import { useAppDispatch } from 'src/store';
import { useState } from 'react';
import { Item } from 'src/types/item';
import { windowOpenTo } from 'src/helpers/common';
const insurancePdf =  "https://worthy.s3.amazonaws.com/certificates/insurance_certificate.pdf";

// interface CalendarEventI {
//     title: string;
//     description: string;
//     location: string;
//     startTime: string;
//     endTime: string;
// }
// const event: CalendarEventI = {
//     title: 'Pickup Appointment',
//     description: 'Pickup appointment for your item',
//     location: '123 Main St, Anytown, USA',
//     startTime: '2034-11-01T10:00:00',
//     endTime: '2034-11-01T11:00:00'
//   };

//   const downloadICSFile = (event: CalendarEventI) => {
//     const link = generateICSCalendarLink(event);
//     const anchor = document.createElement('a');
//     anchor.href = link;
//     anchor.download = 'event.ics';
//     document.body.appendChild(anchor);
//     anchor.click();
//     document.body.removeChild(anchor);
//   };


//   const generateICSCalendarLink = (event: CalendarEventI) => {
//     const { title, description, location, startTime, endTime } = event;
//     const start = startTime.replace(/-/g, '').replace(/:/g, '').replace(/\.\d+/, '');
//     const end = endTime.replace(/-/g, '').replace(/:/g, '').replace(/\.\d+/, '');
//     const icsContent = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nBEGIN:VEVENT\r\nSUMMARY:${escapeICSString(title)}\r\nDESCRIPTION:${escapeICSString(description)}\r\nLOCATION:${escapeICSString(location)}\r\nDTSTART:${start}\r\nDTEND:${end}\r\nEND:VEVENT\r\nEND:VCALENDAR\r\n`;
//     return `data:text/calendar;charset=utf8,${encodeURIComponent(icsContent)}`;
//   };

//   const escapeICSString = (str: string) => {
//     return str.replace(/,/g, '\\,').replace(/;/g, '\\;').replace(/\n/g, '\\n');
//   };
const PickUpSettingMenuInfoMenu = [
    // {
    //     text: 'packing instruction',
    //     icon: <Icon.PackIcon sx={{ mr: 1, fontSize: '1.2em' }} />,
    //     onclick: () => console.log('packing instruction'),
    // },
    {
        text: 'insurance information',
        icon: <Icon.Fill sx={{ mr: 1, fontSize: '1.2em' }} />,
        onclick: () => {
            windowOpenTo({url: insurancePdf, target: '_blank', skipUvid: true});
        },
    },
];

interface PickUpSettingMenuProps {
    items: Item[];
}

const StyledIconButton = styled(IconButton)`
    padding: 0;
`;

function PickUpSettingMenu({items} : PickUpSettingMenuProps) {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (    <Box>
    <StyledIconButton
                            edge="end"
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            aria-haspopup="true"
                            disableRipple >
                            <Icon.MoreVertRounded sx={{ color: 'divider'}} />
                        </StyledIconButton>
        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            color='primary'
                        >
                            {PickUpSettingMenuInfoMenu.map((option) => (
                                <MenuItem
                                    key={option.text}
                                    onClick={option.onclick}
                                >
                                    {option.icon} {option.text}
                                </MenuItem>
                            ))}
                            {/* <Divider variant="middle" /> */}
                            {/* <MenuItem
                                    key={'add-to-calendar'}
                                    onClick={() => downloadICSFile(event)}
                                >
                                    <Icon.AddToCalendarIcon sx={{ mr: 1, fontSize: '1.2em' }} /> Add to calendar
                                </MenuItem> */}
                                <MenuItem
                                    key={'reschedule'}
                                    onClick={() => {
                                        dispatch(setSchedulingMainItem(items));
                                        dispatch(setOpenScheduling(true))
                                        handleClose()
                                    }}
                                >
                                    <Icon.RescheduleCalendar sx={{ mr: 1, fontSize: '1.2em' }} /> Reschedule
                                </MenuItem>
                        </Menu>

            </Box>
    );
}

export default PickUpSettingMenu;
