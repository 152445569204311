import { HIWProps } from 'src/components/infoSections/hiw.tsx';
import submit from '/img/granSubmit.jpeg';
import { printLabel } from 'src/components/ItemCard/StepsActions/PickupStep';
import { Button, CircularProgress, Icon, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Item } from 'src/types/item';
import { useState } from 'react';

const StepsData = (item: Item) => {
    const [loading, setLoading] = useState(false);
    return [
    {
        title: 'Print',
        activeTitle:  <>
            <Button variant='text' disableRipple sx={{p: 0}} onClick={()=> {
                setLoading(true);
                void printLabel(item).finally(()=>setLoading(false));
            }} disabled={!item?.schedulingDetails?.shipment?.shippingLabelUrl || loading}>
                <Typography noWrap color={item?.schedulingDetails?.shipment?.shippingLabelUrl ? 'highlight.main': 'secondary.main'} fontSize={'0.9rem'} fontWeight={500}>
                    Print Labels
                    {!item?.schedulingDetails?.shipment?.shippingLabelUrl || loading ? <CircularProgress size={'0.8em'} color="inherit" sx={{m: 0,ml:1}} />
                        :<Typography component={'span'} fontSize={'0.8em'} ml={1}>
                            <Icon.ExpandMoreShineIcon  fontSize="inherit" transform="rotate(270)" color="inherit" />
                        </Typography>}
                </Typography>
            </Button>
        </>,
        description: <>
        Print your shipping labels and insurance information<br/>
        <Button variant='text' disableRipple sx={{p: 0}} onClick={()=> {
            setLoading(true);
            void printLabel(item).finally(()=>setLoading(false));
            }} disabled={!item?.schedulingDetails?.shipment?.shippingLabelUrl || loading}>
                <Typography noWrap color={item?.schedulingDetails?.shipment?.shippingLabelUrl ? 'highlight.main': 'secondary.main'} fontSize={'0.9rem'} fontWeight={500}>
                    Print Labels
                    {!item?.schedulingDetails?.shipment?.shippingLabelUrl || loading ? <CircularProgress size={'0.8em'} color="inherit" sx={{m: 0,ml:1}} />
                    :<Typography component={'span'} fontSize={'0.8em'} ml={1}>
                        <Icon.ExpandMoreShineIcon  fontSize="inherit" transform="rotate(270)" color="inherit" />
                    </Typography>}
                </Typography>
        </Button>
    </>,
        img: submit,

    },
    {
        title: 'Secure',
        description: 'Secure your item safely in a small container',
        img: submit,

    },
    {
        title: 'Pack',
        description: 'Put the container and inner label in a box (a used box will do)',
        img: submit,
    },
    {
        title: 'Seal',
        description: 'Seal the box and attached the FedEx label to the outside',
        img: submit,
    },
]};


export const HIWForPrepareForDropOffPropsData = ({item}: {item: Item}): HIWProps => ({
    steps: StepsData(item),
    title: 'Preparing for Drop-Off',
    currentStepIndex: -1,
});
