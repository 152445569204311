import React, {  forwardRef } from "react";
import { Button,  Link, Stack, styled, Typography ,Box, SnackbarContent} from "@worthy-npm/worthy-common-ui-components";
import { useAppDispatch } from "src/store";
import { markNotificationAsRead } from "src/store/notificationsSlice";


const StyledSnackbar = styled(SnackbarContent)(({theme})=>({
    width: '100%',
    backgroundColor: theme.palette.text.primary,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.background.default}`,
    '& .MuiSnackbarContent-message':{
      padding: 0,
    }
}));

const StyledButton = styled(Button)(({theme})=>({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '&:hover':{
        backgroundColor: theme.palette.common.white,
    }
}));

export interface ItemNotificationProps {
    id: string;
    message: string;
    description: string;
    img: React.ReactNode;
    offline?: boolean;
    learnMore?: () => void;
    onClose: (key: string) => void;
  }

const ItemNotification = forwardRef<HTMLDivElement, ItemNotificationProps>(({ id,message, img, description, learnMore, onClose, offline }, ref) => {
  const dispatch = useAppDispatch();

  const handleOnClose = () => {
    onClose(id);
    if(offline){
      return
    }
    void dispatch(markNotificationAsRead(id));
  }
  const handleLearnMore = () => {
    if(!learnMore){
      return;
    }
    learnMore();
    handleOnClose()
  }
    const Message = () => (
        <Stack direction={ 'row' } gap={ 2 }>
            <Stack gap={ 'auto' } m={ 1 } justifyContent={ 'space-between' }>
                <Typography variant="h5">{ message }</Typography>
                <Stack gap={ { sm: 1 } } alignItems={ 'flex-start' }>
                    { learnMore && <Button disableRipple sx={ { p: 0 } } onClick={ handleLearnMore }>
                      <Typography color={ 'tertiary.main' }>
                        <Stack direction={ 'row' } gap={ { xs: 0.5, sm: 1 } } alignItems={ 'center' }>
                          <Box textAlign="justify">
                            <Link underline="none" color={ 'inherit' } fontSize={ '1.2rem' }>
                              <Typography component={ 'span' }>
                                Learn more&nbsp;
                              </Typography>
                            </Link>
                            <Typography variant="body1" color="common.white" component={ 'span' }>
                                { description }
                            </Typography>
                          </Box>
                        </Stack>
                      </Typography>
                    </Button>
                    }
                </Stack>
            </Stack>
            <Stack gap={ 2 } justifyContent={ 'space-between' }>
                <Box width={ 125 } height={ 82 } borderRadius={ 1 } overflow={ 'hidden' }>
                    { img }
                </Box>
                <StyledButton onClick={ handleOnClose }>Okay</StyledButton>
            </Stack>
        </Stack>
    );

  return (
        <StyledSnackbar
            ref={ref}
            id={id}
            message={<Message />}
        />
  );
});




  ItemNotification.displayName = 'ItemNotification';

export default ItemNotification;
