import { StyledNavToolBar, TabsContainer } from 'src/styles/nav/navigation.styles.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@worthy-npm/worthy-common-ui-components';
import { sdRoutes } from 'src/routes.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import React, { useEffect, useState } from 'react';
import { getRoute, setRoute } from 'src/store/sharedSlice';
import { selectError } from 'src/store/itemsSlice';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';

const Navigation = () => {
    const location = useLocation();
    const rootPath = useAppSelector(getRoute);
    const error = useAppSelector(selectError);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<string>(rootPath);
    const isPc = useDesktopVersion();

    const filteredRoutes = [sdRoutes.shipping, sdRoutes.myItems]

    useEffect(() => {
        setSelectedTab(rootPath);
    }, [rootPath]);

    useEffect(() => {
        if (location.pathname !== rootPath) {
            navigate(rootPath);
        }
    }, [rootPath, location.pathname, navigate]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue !== selectedTab) {
            setSelectedTab(newValue);
            dispatch(setRoute(newValue));
            navigate(newValue);
        }
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }

    return (
        <StyledNavToolBar position="sticky" elevation={ 0 }>
            <TabsContainer
                value={ selectedTab }
                onChange={ handleTabChange }
                variant={ isPc ? 'standard' : 'fullWidth' }
                centered
                selectionFollowsFocus
            >
                { filteredRoutes.map((route, idx) => (
                    <Tab
                        key={ route }
                        className="tab-wrapper"
                        label={ route === '/shipping' ? 'Shipping' : 'My Items' }
                        value={ route }
                        {...a11yProps(idx)}
                        disabled={ !!error }
                    />
                )) }
            </TabsContainer>
        </StyledNavToolBar>
    );
};

export default React.memo(Navigation);
