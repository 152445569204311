import { Paper, Stack, styled } from '@worthy-npm/worthy-common-ui-components';
import AccordionStepper from 'src/components/AccordionStepper/AccordionStepper';
import DesktopStepper from 'src/components/DesktopStepper/DesktopStepper';
import { StyledAccordionStepperWrapper } from 'src/components/ItemCard/StepsActions/StepsActions.styles';
import { SchedulingType } from 'src/constants/item.constants';
import { HIWForPrepareForDropOffPropsData } from 'src/data/HIWForDropOff';
import { HIWForPrepareForPickupPropsData } from 'src/data/HIWForPrepareForPickup';
import { useDesktopVersion } from 'src/helpers/deviceSize';
import { Item } from 'src/types/item';



const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.default,
    outline: `1px solid ${ theme.palette.divider }`,
}));

export interface PrepareForPickupDropOffDesktopProps {
    onClose?: () => void;
    item: Item;
}

export const PrepareForPickupDropOffDesktop = ({ item }: PrepareForPickupDropOffDesktopProps) => {
    const isDesktop = useDesktopVersion();
    const HIWObjectData = item?.schedulingDetails?.type === SchedulingType.PICKUP ? HIWForPrepareForPickupPropsData({item}) : HIWForPrepareForDropOffPropsData({item});
    return isDesktop ? (
        <StyledPaper elevation={ 0 }>
            <DesktopStepper { ...HIWObjectData } item={ item } />
        </StyledPaper>
    ) : (
        <Stack gap={ 2 }>
            <StyledAccordionStepperWrapper elevation={ 0 }>
                <AccordionStepper { ...HIWObjectData } />
            </StyledAccordionStepperWrapper>
        </Stack>
    );
};
