import { Box, Divider, RadioGroup, Skeleton, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import { CtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';
import popular from '/img/mostPopular.svg';


export const ItemSkeleton = styled(Skeleton)(({ theme }) => ({
    maxWidth: '554px',
    width: '100%',
    height: '167px',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
}));

export const ItemsCountWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
`;

export const ItemsCount = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Uncut Sans Variable',
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '21px',
    letterSpacing: '-0.3px',
}));

export const Title = styled(Typography)<{ disabled?: boolean }>(({ theme, disabled }) => ({
    color: disabled ? theme.palette.divider : theme.palette.text.primary,
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '21px',
    alignSelf: 'flex-start',
}));

export const SchedulingMethodsContainer = styled(Box, {
    shouldForwardProp: propName => ![ 'empty' ].includes(propName as string),
})<{ empty?: boolean }>(({ theme, empty }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: empty ? theme.spacing(1) : theme.spacing(3),
    alignSelf: 'stretch',
}));

export const SchedulingListWrapper = styled(Box)(({ theme }) => ({
    maxWidth: '602px',
    width: '100%',
    margin: '0 auto',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
}));

export const MethodWrapper = styled(Box, {
    shouldForwardProp: propName => ![ 'banner', 'experiment', 'isSelected' ].includes(propName as string),
})<{ banner: boolean, experiment: boolean, isSelected: boolean }>(({ theme, banner, experiment, isSelected }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 0 0',
    alignSelf: 'stretch',
    alignItems: 'center',
    gap: theme.spacing(2),
    ...(experiment && {
        border: isSelected ? `1px solid ${ theme.palette.tertiary.main }` : `1px solid ${ theme.palette.divider }`,
        borderRadius: '4px',
        padding: '6px 16px',
        ...(banner && {
            backgroundImage: `url(${ popular })`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'calc(100% + 5px) calc(0% - 4px)',
            backgroundSize: 'auto 100%',
        }),
    }),
}));

export const PartnersWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    alignSelf: 'stretch',
    [theme.breakpoints.up('md')]: {
        order: 1,
    },
}));

export const FloatingDivider = styled(Divider)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const ItemCountInShipment = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    alignSelf: 'stretch',
    [theme.breakpoints.up('md')]: {
        marginTop: '25px',
    },
}));

export const InsuranceText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '14px',
    letterSpacing: '-0.28px',
}));

export const Logos = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '0px 8px',
    alignItems: 'center',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        marginLeft: '10px',
    },
}));

export const MethodsCtaBtn = styled(CtaBtn)(({ theme }) => ({
    alignSelf: 'stretch',
    fontSize: '18px',
    flex: '0 1 0',
    padding: '8px',
    '&:disabled': {
        backgroundColor: theme.palette.divider,
        color: '#919AA7',
    },
}));

export const ScheduleCallBtn = styled(CtaBtn)(({ theme }) => ({
    background: theme.palette.primary.main,
    alignSelf: 'stretch',
    fontSize: '18px',
    flex: '0 1 0',
    padding: '8px',
    alignItems: 'center',
    '&:disabled': {
        color: '#919AA7',
    },
}));

export const DisabledWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.tertiary,
    border: `1px solid ${ theme.palette.divider }`,
}));

export const DisabledCallWrapper = styled(Box)(() => ({
    alignSelf: 'stretch',
}));

export const StarBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '68px',
    height: '43px',
    gap: '10px',
    color: theme.palette.tertiary.main,
    marginTop: 'auto',
}));

export const SchedulingOptions = styled(RadioGroup, {
    shouldForwardProp: propName => ![ 'banner' ].includes(propName as string),
})<{banner: boolean}>(({ banner }) => ({
    display: 'flex',
    ...(banner && {
        gap: '8px',
    })
}));
