import {  Box, Button, Collapse, Divider, Icon, Paper, Stack, styled, Typography } from "@worthy-npm/worthy-common-ui-components";
import React, { useState } from "react";
import { NUMBER_OF_ITEMS_BEFORE_EXPAND } from "src/components/ItemCard/ItemCard";
import ItemCardDetails from "src/components/ItemCard/ItemCardDetails";
import { useDesktopVersion } from "src/helpers/deviceSize";
import { Item } from "src/types/item";

export const StyledInnerContainer = styled(Paper)(({ theme }) => ({
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
        outline: `1.5px solid ${theme.palette.action.disabled}`,
    }
}));

const GAP_SIZE = 1.5;

interface ItemCardBundleListProps {
    items: Item[];
    onCheckboxChange?: (item: Item, isChecked: boolean) => void;
}

export const ItemCardBundleList = ({ items, onCheckboxChange }:ItemCardBundleListProps) => {
    const [expandedItemList, setExpandedItemList] = useState(false);
    const isDesktop = useDesktopVersion();

    const handleItemListToggle = () => {
        setExpandedItemList(!expandedItemList);
    };

    return (<>
    <>
    {!(items.length > 1 && isDesktop) && (
        <StyledInnerContainer>
            <Stack gap={GAP_SIZE}>
            {items.slice(0, isDesktop ? 1: NUMBER_OF_ITEMS_BEFORE_EXPAND).map((item, index) => (
                <React.Fragment key={item.publicId}>
                    {index > 0 && (
                            <Divider />
                    )}
                        <ItemCardDetails
                            onCheckboxChange={onCheckboxChange}
                            item={item}
                        />
                </React.Fragment>
            ))}
            <Collapse
                in={expandedItemList}
                timeout="auto"
                unmountOnExit
            >
                {items.slice(isDesktop ? 1: NUMBER_OF_ITEMS_BEFORE_EXPAND).map((item, index) => (
                    <Stack
                        key={item.publicId}
                        gap={2}
                    >
                        { !isDesktop && <Divider sx={{ mt: index > 0 ? 2 : 0 }} />}
                        <ItemCardDetails
                            onCheckboxChange={onCheckboxChange}
                            item={item}
                        />
                    </Stack>
                ))}
            </Collapse>
            </Stack>
        </StyledInnerContainer>
    )}
        {!isDesktop && items.length > NUMBER_OF_ITEMS_BEFORE_EXPAND && (
            <Box textAlign={expandedItemList ? 'end' :  'center'}>
             <Button
             disableRipple
             variant="text"
             onClick={handleItemListToggle}
             sx={{ color: 'highlight.main' ,mt: 1}}
         >
             {expandedItemList ? (
                <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                 <Typography
                     variant="caption"
                     fontWeight={'meduim'}
                     sx={{ textDecoration: 'underline' }}
                 >
                     Show less items 
                 </Typography>
                     <Icon.ExpandMoreShineIcon
                         sx={{ fontSize: '1em', transform: 'rotate(180deg)' }}
                     />
                     </Stack>
             ) : (
                 <Stack
                     direction={'row'}
                     gap={0.5}
                     alignItems={'center'}
                 >
                     <Typography
                         variant="caption"
                         fontWeight={'meduim'}
                         sx={{ textDecoration: 'underline' }}
                     >
                         Load {items.length - NUMBER_OF_ITEMS_BEFORE_EXPAND}{' '}
                         more items
                     </Typography>
                     <Icon.ExpandMoreShineIcon
                         sx={{ fontSize: '1em' }}
                     />
                 </Stack>
             )}
         </Button>
         </Box>
        )}
    
    </>
    </>);
}