import _ from 'lodash';
import { DropOffLocations } from 'src/services/api/worthy.service.ts';
import { getAddressFromGoogle, GoogleAddressResponse } from 'src/services/googleMaps.service.ts';
import { Address } from 'src/store/schedulingSlice/scheduling.slice';
import { LocationType } from 'src/types/shipping.ts';

export const prepareDropOffLocations = async (
  shipmentLocations: LocationType[],
): Promise<DropOffLocations> => {
  const locations: DropOffLocations = {};

  try {
    if (shipmentLocations?.length === 0) {
      throw new Error('Shipment locations are empty. Unable to set drop off');
    }

    const dropOffLocations: GoogleAddressResponse[] = await Promise.all(
      shipmentLocations.map((loc) => getAddressFromGoogle(loc.address)),
    );

    dropOffLocations.forEach((dropOffLocation, idx: number) => {
      const formattedAddress = dropOffLocation.results[0]?.formatted_address;
      locations[idx] = {
        name: shipmentLocations[idx].name,
        address: formattedAddress,
      };
    });

    return locations;
  } catch (error) {
    console.error('Error fetching drop-off locations:', error);
    throw error;
  }
};

export const addressToObject = (address: string)  => {
  let [street,apt , city, state, zipcode] = ['','', '', '', ''];
  const addressArray = _.map(address.split(','), (a)=> _.trim(a));
  if(addressArray.length === 5) {
    [street, apt, city, state, zipcode] = addressArray
  } else if (addressArray.length === 4) {
    [street, city, state, zipcode] = addressArray
  }
    return {
      street, apt, city, state, zipcode
    }
  }

  export const addressToSuggestionDescription = (address: string)  => {
    const {street, city, state, zipcode} = addressToObject(address);
    return `${street}, ${city}, ${state}, ${zipcode}`
    }

export const addressToFormattedAddress = (obj: Pick<Address, "city" | "street" | "state" | "zipcode"> )  => {
      const {street, city, state, zipcode} = obj;
      return `${street}, ${city}, ${state}, ${zipcode}`}

export const addressWithoutApt = (address: string) => {
  const {street, city, state, zipcode} = addressToObject(address);
  return `${street}, ${city}, ${state}, ${zipcode}`
}
export const objToAddress = (obj: Pick<Address, "city" | "street" | "state" | "zipcode" | 'apt'>) => {
  const {street, city, state, zipcode, apt} = obj;
  if(apt){
    return `${street}, ${apt}, ${city}, ${state}, ${zipcode}`
  }
  return `${street}, ${city}, ${state}, ${zipcode}`
}