/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

// For city name fallbacks - we'll try multiple types in a priority order
export const possibleCityTypes = [
  'locality',           // Most common city type
  'postal_town',        // Used in some areas for the postal city
  'sublocality_level_1', // Major district
  'administrative_area_level_3', // Sometimes used for cities/towns
  'neighborhood',       // Small regions
  'administrative_area_level_2' // County level - least preferred but better than nothing
];


export interface AddressSuggestion {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export const initializeGoogleMapsServices = () => {
  if (window.google?.maps?.places?.AutocompleteService) {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService() as google.maps.places.AutocompleteService;
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    ) as google.maps.places.PlacesService;
    const geocoder = new window.google.maps.Geocoder() as google.maps.Geocoder;
    return { autocompleteService, placesService, geocoder };
  } else {
    //TODO: add error handling
    console.error('Google Maps API failed to load');
    return { autocompleteService: null, placesService: null, geocoder: null };
  }
};

export const getPlacePredictions = (
  autocompleteService: google.maps.places.AutocompleteService,
  input: string,
): Promise<AddressSuggestion[]> => {
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions(
      { input, types: ['address'], componentRestrictions: { country: 'us' } },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const newSuggestions = predictions.map((prediction) => ({
            description: prediction.description,
            place_id: prediction.place_id,
            structured_formatting: {
              main_text: prediction.structured_formatting.main_text,
              secondary_text: prediction.structured_formatting.secondary_text,
            },
          }));
          resolve(newSuggestions);
        } else {
          resolve([]);
        }
      },
    );
  });
};

interface AddressDetails {
  addressStreet: string;
  addressCity: string;
  addressState: string;
  addressZipcode: string;
}

export const getPlaceDetails = (
  placesService: google.maps.places.PlacesService,
  placeId: string,
): Promise<AddressDetails> => {
  return new Promise((resolve, reject) => {
    placesService.getDetails(
      { placeId },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          const addressComponents = place.address_components;
          const addressDetails: AddressDetails = {
            addressStreet: '',
            addressCity: '',
            addressState: '',
            addressZipcode: '',
          };

          // Create a map to store components by type for faster lookup
          const componentsByType = new Map<string, string>();
          
          // First pass: collect basic address components
          addressComponents?.forEach((component) => {
            const types = component.types;
            
            // Store each component type for later lookup
            types.forEach(type => {
              componentsByType.set(type, component.long_name);
            });
            
            // Handle street components directly
            if (types.includes('street_number')) {
              addressDetails.addressStreet = component.long_name + ' ' + addressDetails.addressStreet;
            }
            if (types.includes('route')) {
              addressDetails.addressStreet += component.long_name;
            }
            
            // Get state
            if (types.includes('administrative_area_level_1')) {
              addressDetails.addressState = component.short_name;
            }
            
            // Get zipcode
            if (types.includes('postal_code')) {
              addressDetails.addressZipcode = component.long_name;
            }
          });
          
          // Second pass: find city using priority list
          for (const cityType of possibleCityTypes) {
            if (componentsByType.has(cityType)) {
              addressDetails.addressCity = componentsByType.get(cityType) || '';
              break; // Stop at the first match based on priority
            }
          }

          resolve(addressDetails);
        } else {
          reject(status);
        }
      },
    );
  });
};

export const getCityAndStateFromZipcode = async (
  geocoder: google.maps.Geocoder,
  zipcode: string,
): Promise<{ city: string; state: string }> => {
  return new Promise((resolve, reject) => {
    void geocoder.geocode({ address: zipcode }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
        const addressComponents = results[0].address_components;
        let city = '';
        let state = '';
        let isUS = false;

        // Create a map to store components by type for faster lookup
        const componentsByType = new Map<string, string>();
        
        // First pass: collect all component types and check if this is in the US
        addressComponents.forEach((component) => {
          const types = component.types;
          
          // Store each component type for later lookup
          types.forEach(type => {
            componentsByType.set(type, component.long_name);
          });
          
          // Check for US
          if (types.includes('country') && component.short_name === 'US') {
            isUS = true;
          }
          
          // Get state
          if (types.includes('administrative_area_level_1')) {
            state = component.short_name;
          }
        });
        
        // Second pass: find city using priority list
        for (const cityType of possibleCityTypes) {
          if (componentsByType.has(cityType)) {
            city = componentsByType.get(cityType) || '';
            break; // Stop at the first match based on priority
          }
        }

        if (isUS) {
          resolve({ city, state });
        } else {
          reject('Location is not in the United States');
        }
      } else {
        reject(status);
      }
    });
  });
};
