export const runtimeType = import.meta.env.VITE_APP_ENV;
export const runtimeTitle = import.meta.env.VITE_APP_TITLE;
export const runtimeGtmId = import.meta.env.VITE_APP_GTM_ID;
export const gtmAuth = import.meta.env.VITE_APP_GTM_AUTH;
export const gtmEnvId = import.meta.env.VITE_APP_GTM_ENV_ID;
export const rollbarToken = import.meta.env.VITE_APP_ROLLBAR_TOKEN;
export const applitoolsApiKey = import.meta.env.APPLITOOLS_API_KEY;
export const monolithUrl = import.meta.env.VITE_APP_MONOLITH_URL;
export const backendUrl = import.meta.env.VITE_APP_BACKEND_URL;
export const appUrl = import.meta.env.VITE_APP_URL;
export const contentSiteUrl = import.meta.env.VITE_APP_CONTENT_SITE_URL;
export const blogUrl = import.meta.env.VITE_APP_BLOG_URL;
export const authUrl = import.meta.env.VITE_APP_AUTH_URL;
export const submissionUrl = import.meta.env.VITE_APP_SUBMISSION_URL;
export const defaultCalendly = import.meta.env.VITE_APP_DEFAULT_CALENDLY;
export const calendlyWatchesUrl = import.meta.env.VITE_APP_CALENDLY_WATCHES_URL;
export const calendlyJbUrl = import.meta.env.VITE_APP_CALENDLY_JB_URL;
export const calendlyUltraUrl = import.meta.env.VITE_APP_CALENDLY_ULTRA_URL;
export const calendlyHvUrl = import.meta.env.VITE_APP_CALENDLY_HV_URL;
export const calendlyUnverifiedUrl = import.meta.env.VITE_APP_CALENDLY_UNVERIFIED_URL;
export const googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
export const calendlyToken = import.meta.env.VITE_APP_CALENDLY_TOKEN;
export const prod = import.meta.env.PROD;
export const viteAppVersion = import.meta.env.VITE_APP_VERSION;
