import { Icon, Popover, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    CallDotsContent, CallDotsItem,
    CallScheduled,
    ScheduleDots,
} from 'src/styles/faq/scheduleCall.styles.tsx';
import React, { useEffect, useRef, useState } from 'react';
import Calendly from 'src/helpers/calendly.ts';
import { useAppSelector } from 'src/store';
import { formatDateTimeRange } from 'src/helpers/common.ts';
import { getUser } from 'src/store/userSlice';
import { getItemAppointment } from 'src/store/itemsSlice';
import { Icon as CommonIcons } from 'src/components/Icons.tsx';
import { Item } from 'src/types/item.ts';
import axios from 'axios';
import { getCalendlyToken } from 'src/config';

interface ICallDataProps {
    item: Item | undefined;
    darkMode?: boolean;
    superItem?: Item;
}

// pass main item to which connect Calendly to schedule a call
const ScheduleCallData = ({ item, superItem, darkMode = false }: ICallDataProps) => {
    const user = useAppSelector(getUser);

    const [ open, setOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

    const appointment = useAppSelector((state) => getItemAppointment(state, item?.publicId));
    const [ scheduled, setScheduled ] = useState<string>('');
    const cancelPrevCallRef = useRef<boolean>(false);

    useEffect(() => {
        window.addEventListener('message', handleCalendly);
    }, []);

    useEffect(() => {
        if (appointment && !isDatePassed(new Date(appointment.endTime))) {
            const date = formatDateTimeRange(appointment.startTime, appointment.endTime);
            setScheduled(date);
        } else {
            setScheduled('');
        }

    }, [ appointment, item, superItem ]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1 && cancelPrevCallRef.current) {
                cancelScheduledCall();
                cancelPrevCallRef.current = false;
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const cancelScheduledCall = async () => {
        if (appointment && superItem && superItem?.publicId !== item?.publicId) {
            const url = appointment.uri;
            const uvid = url.split('/').pop();
            try {
                await axios({
                    method: 'post',
                    url: `https://api.calendly.com/scheduled_events/${ uvid }/cancellation`,
                    headers: {
                        Authorization: `Bearer ${ getCalendlyToken() }`,
                    },
                    data: { reason: 'new schedule' },
                });
            } catch (error) {
                console.error(`Error cancelling scheduled call: ${uvid}`, error);
            }
        }
    };

    const isDatePassed = (date: Date) => {
        const now = new Date();
        return date < now;
    };

    const handleScheduleCall = (newItem?: Item) => {
        const schedulingItem = newItem || item;

        const link = Calendly.getCalendlyLinkByValueCategory(schedulingItem, user);
        Calendly.showPopupWidget(link, Calendly.calendlyParams(schedulingItem, user));
    };

    const rescheduleCall = () => {
        if (!appointment) {
            handleScheduleCall();
            return;
        }

        if (superItem && superItem?.publicId !== item?.publicId) {
            cancelPrevCallRef.current = true;
            handleScheduleCall(superItem);
            return;
        }

        Calendly.showPopupWidget(appointment.rescheduleUrl, Calendly.calendlyParams(item, user));
    };

    const cancelCall = () => {
        if (!appointment) {
            return;
        }
        Calendly.showPopupWidget(appointment.cancelUrl, Calendly.calendlyParams(item, user));
    };

    return (
        <CallScheduled id="schedule-call-data" darkMode={ darkMode }>
            <ScheduleDots
                darkMode={ darkMode }
                onClick={ handleOpen }
                variant="default"
                size="small"
                color="secondary"
            >
                <CommonIcons.VertThreeDots />
            </ScheduleDots>
            <Popover
                anchorOrigin={ {
                    vertical: 'center',
                    horizontal: 'right',
                } }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'left',
                } }
                open={ open }
                onClose={ handleClose }
                anchorEl={ anchorEl }
            >
                <CallDotsContent>
                    <CallDotsItem
                        onClick={ rescheduleCall }
                    >
                        <Icon.CalendarReschedule />
                        <Typography variant="caption">Reschedule call</Typography>
                    </CallDotsItem>
                    <CallDotsItem
                        onClick={ cancelCall }
                        cancel={ true }
                    >
                        <Icon.CalendarCancel />
                        <Typography
                            variant="caption"
                            color="text.primary"
                        >
                            Cancel call
                        </Typography>
                    </CallDotsItem>
                </CallDotsContent>
            </Popover>
            <Typography
                variant="h3"
                textAlign="center"
                fontSize="24px !important"
            >
                Call Scheduled For:
            </Typography>
            <Typography
                variant="caption"
                textAlign="center"
                fontWeight="300"
            >
                { scheduled }
            </Typography>
        </CallScheduled>

    );
};

export default ScheduleCallData;
