import { createBrowserRouter, Navigate } from 'react-router-dom';
import Error from './pages/Error';
import Shipping from 'src/pages/shipping.tsx';
import Protected from 'src/pages/protected.tsx';
import MyItems from './pages/MyItems';
import BaseLayout from 'src/layout/baseLayout';

export const sdRoutes = {
    shipping: '/shipping',
    myItems: '/my-items',
}

export const routes = [
    {
        element: <Protected />,
        children: [
            {
                element: <BaseLayout />,
                children: [
                    {
                        path: '/',
                        element: (
                            <Navigate
                                to={ sdRoutes.shipping }
                                replace
                            />
                        ),
                        errorElement: <Error />,
                    },
                    {
                        path: sdRoutes.shipping,
                        element: <Shipping />,
                        errorElement: <Error />,
                    },
                    {
                        path: sdRoutes.myItems,
                        element: <MyItems />,
                        errorElement: <Error />,
                    },
                ],
            },

        ],
    },
];

// https://reactrouter.com/en/main/routers/create-browser-router
const router = createBrowserRouter(routes);

export default router;
