import React, { useEffect, useMemo } from 'react';
import AddressInput from 'src/components/AddressInput/AddressInput.tsx';
import PickupSlots from 'src/components/PickupSlots/PickupSlots.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getSchedulingCurrentStep,
    setStepsLength,
    getSelectedShippingMethod,
} from 'src/store/schedulingSlice';
import { SchedulingType } from 'src/constants/item.constants';
import SchedulingMethods from 'src/components/SchedulingMethods/SchedulingMethods.tsx';
import { Box } from '@worthy-npm/worthy-common-ui-components';
import { getIsAllShippingPending } from 'src/store/itemsSlice';

const SchedulingPopUp: React.FC<{ isPC: boolean }> = ({ isPC }) => {
    const dispatch = useAppDispatch();

    const allPending = useAppSelector(getIsAllShippingPending);
    const currentStep: number = useAppSelector(getSchedulingCurrentStep);
    const selectedShippingMethod: '' | SchedulingType = useAppSelector(getSelectedShippingMethod);
    const steps = useMemo(() => {
        const commonSteps = [
            { component: <SchedulingMethods isPC={ isPC } allPending={ allPending } key="0" /> },
            { component: <AddressInput isPC={ isPC } key="1" /> },
        ];

        if (selectedShippingMethod === SchedulingType.PICKUP) {
            commonSteps.push({ component: <PickupSlots isPC={ isPC } key="2" /> });
        }

        return commonSteps;
    }, [ selectedShippingMethod, allPending ]);

    useEffect(() => {
        dispatch(setStepsLength(steps.length));
    }, [ dispatch, steps.length ]);

    return <Box p={ 3 }>{ steps[currentStep].component }</Box>;
};

export default SchedulingPopUp;
