import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@worthy-npm/worthy-common-ui-components';
import { useEffect } from 'react';
import { AuthStatus, useAuth } from 'src/context/authContext.tsx';
import { LoadingDiamondImg } from 'src/components/loader.tsx';
import { getAppUrl } from 'src/config';
import { redirectTo } from 'src/helpers/common';

const Protected = () => {
    const { getUser, user, authenticationStatus } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let ignore = false;

        async function startFetching() {
            if (!ignore) {
                await getUser();
            }
        }

        startFetching();

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        if (authenticationStatus === AuthStatus.NOT_LOGGED) {
            redirectTo({ url: getAppUrl() + '/signin' }); // Replace '/login' with the path you want to redirect to
        }
    }, [ authenticationStatus, navigate ]);

    if (!user) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="100px"
            >
                <LoadingDiamondImg />
            </Box>
        );
    } else {
        return <Outlet />;
    }
};

export default Protected;
