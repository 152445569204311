import GTM from 'react-gtm-module';
import { GaActions, GaEvents, gaNullEventFields } from './ga.constants';
import { appVersion } from 'src/config';

class Ga {
  public static EventNames = GaEvents;
  public static EventActions = GaActions;

  private static SendEvent = (event: Omit<GA.Event, 'appVersion'>) => {
    const { eventName, ...rest } = event;

    GTM.dataLayer({
      dataLayer: {
        ...gaNullEventFields,
        event: eventName,
        appVersion: appVersion() || null,
        ...rest,
      },
    });
  }

  public static SendInteractionEvent = (event: GA.InteractionEvent) => {
    Ga.SendEvent(event);
  };

  public static SendFeedbackEvent = (event: Omit<GA.FeedbackEvent, 'event' | 'feedbackType'>) => {
    Ga.SendEvent(event);
  };

  // feedback actions
  public static SendActionOpen(data: Omit<GA.FeedbackEvent, 'action' | 'eventName' | 'event' | 'feedbackType'>) {
    this.SendFeedbackEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Open
    });
  }

  public static SendActionSuccess(data: Omit<GA.FeedbackEvent, 'action' | 'eventName' | 'event' | 'feedbackType'>) {
    this.SendFeedbackEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Success,
    });
  }

  public static SendActionFailure(data: Omit<GA.FeedbackEvent, 'action' | 'eventName' | 'event' | 'feedbackType'>) {
    this.SendFeedbackEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Failure,
    });
  }

  public static SendActionClose(data: Omit<GA.FeedbackEvent, 'action' | 'eventName' | 'event' | 'feedbackType'>) {
    this.SendFeedbackEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Close,
    });
  }

  // interaction actions
  public static SendActionSelect(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Select,
    });
  }

  public static SendActionClear(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Clear,
    });
  }

  public static SendActionFocus(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Focus,
    });
  }

  public static SendActionInput(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Input,
    });
  }

  public static SendActionSubmit(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Submit,
    });
  }

  public static SendActionToggle(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Toggle,
    });
  }

  public static SendActionNavigate(data: Omit<GA.InteractionEvent, 'action' | 'eventName' | 'event' | 'interactionType'>) {
    this.SendInteractionEvent({
      ...data,
      event: this.EventNames.Interaction,
      interactionType: this.EventActions.Navigate,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Navigate,
    });
  }

  public static SendActionSort(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Sort,
    });
  }

  // we should try to avoid using this action
  public static SendActionClick(data: Omit<GA.InteractionEvent, 'action' | 'eventName'>) {
    this.SendInteractionEvent({
      ...data,
      eventName: this.EventNames.Interaction,
      action: this.EventActions.Click,
    });
  }
}

export default Ga;
