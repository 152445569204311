import { Box, Paper, Stack, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import { Item } from 'src/types/item.ts';
import React from 'react';
import { ItemSkeleton, SchedulingListWrapper } from 'src/components/SchedulingMethods/SchedulingMethods.styles.tsx';
import ItemCardDetails from 'src/components/ItemCard/ItemCardDetails';
import { usePreSchedulingItems } from 'src/hooks/usePreSchedulingItems.ts';
import { ItemGroups } from 'src/constants/itemCard.constants.tsx';

const EmptyStateMessages: Record<keyof typeof ItemGroups, string> = {
    Valid: 'Items approved for shipping will appear here.',
    Pending: '',
};
const EmptyMsgWrapper = styled(Stack)`
    border-radius: 8px;
    padding: 24px 1px;
    height: 86px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 16px 0px;
    background-clip: padding-box;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%237A8994' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

interface SchedulingListProps {
    items: Item[] | [];
    onCheckboxChange: (item: Item, isChecked: boolean) => void;
}

const SchedulingList: React.FC<SchedulingListProps> = (props: SchedulingListProps) => {
    const { items = [], onCheckboxChange } = props;
    const groupedItemsMemo = usePreSchedulingItems(items);
    const groupTitles: Record<keyof typeof ItemGroups, string> = {
        Valid: 'Approved for Shipping',
        Pending: 'Items Pending Approval',
    };

    const getEmptyGroup = (group: keyof typeof ItemGroups) => (
        <Stack
            key={ group }
            alignItems={ 'flex-start' }
            mt={ 3 }
        >
            <Typography
                variant="body1"
                fontWeight={ { md: '500' } }
                alignSelf={ { xs: 'center', md: 'flex-start' } }
                color={ { xs: 'secondary.main', md: 'text.primary' } }
            >
                { groupTitles[group] }
            </Typography>
            <EmptyMsgWrapper>
                <Typography
                    key={ group }
                    fontWeight={ '500' }
                    textAlign={ 'center' }
                >
                    { EmptyStateMessages[group] }
                </Typography>
            </EmptyMsgWrapper>
        </Stack>
    );

    return (
        <SchedulingListWrapper>
            { ([ ItemGroups.Valid, ItemGroups.Pending ] as const).map((group, index) => (
                !groupedItemsMemo[group] ? (
                    EmptyStateMessages[group] && getEmptyGroup(group)
                ) : (
                    <Stack
                        key={ group }
                        alignItems="flex-start"
                        mt={ index == 0 ? 2 : 1 }
                    >
                        <Typography
                            variant="body1"
                            fontWeight={ { md: '500' } }
                            alignSelf={ { xs: 'center', md: 'flex-start' } }
                            color={ { xs: 'secondary.main', md: 'text.primary' } }
                            sx={ { mb: 1.5 } }
                        >
                            { groupTitles[group] }
                        </Typography>
                        { groupedItemsMemo[group]?.map((item) => (
                            item.publicId.includes('temp') ? (
                                <ItemSkeleton
                                    key={ item.publicId }
                                    variant="rectangular"
                                    animation="wave"
                                />
                            ) : (
                                <Box key={ item.publicId } mb={ 2 } width="100%">
                                    <Paper elevation={ 0 } sx={ { p: 2 } }>
                                        <ItemCardDetails
                                            item={ item }
                                            onCheckboxChange={ onCheckboxChange }
                                        />
                                    </Paper>
                                </Box>
                            )
                        )) }
                    </Stack>
                )
            )) }
        </SchedulingListWrapper>
    );
};

export default SchedulingList;
