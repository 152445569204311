import {
    CalendlySchedule,
    NotSureText,
    ScheduleCallBtn,
    ScheduleCallWrapper,
} from 'src/styles/faq/scheduleCall.styles.tsx';
import { useEffect, useState } from 'react';
import Calendly from 'src/helpers/calendly.ts';
import { useAppSelector } from 'src/store';
import { formatDateTimeRange, getMainItemByValueCategory, isDatePassed } from 'src/helpers/common.ts';
import { getUser } from 'src/store/userSlice';
import { getItemAppointment } from 'src/store/itemsSlice';
import { Item } from 'src/types/item.ts';
import ScheduleCallData from 'src/components/scheduleCall/scheduleCallData.tsx';
import Ga from 'src/services/ga.service/ga.ts';
import _ from 'lodash';

interface ScheduleCallProps {
    itemList: Item[];
}

const ScheduleCall = ({ itemList }: ScheduleCallProps) => {
    const user = useAppSelector(getUser);

    const [ mainItem, setMainItem ] = useState<Item | undefined>(undefined);
    const appointment = useAppSelector((state) => getItemAppointment(state, mainItem?.publicId));
    const [ scheduled, setScheduled ] = useState<string>('');
    const [ newCallableItem, setNewCallableItem ] = useState<Item>();

    useEffect(() => {
        window.addEventListener('message', handleCalendly);
    }, []);

    useEffect((): void => {
        const items: Item[] = itemList || [];
        if (!items.length) {
            return;
        }

        let callableItem = items.find((item) => item.appointmentDetails);
        const watch = _.find(items, (item) =>
            item.itemType?.toLowerCase().includes('watch'),
        );

        if (!callableItem) {
            callableItem = watch || getMainItemByValueCategory(items);
        }

        setMainItem(callableItem);

    }, [ itemList ]);

    useEffect(() => {
        const watch = _.find(itemList, (item) =>
            item.itemType?.toLowerCase().includes('watch'),
        );

        if (appointment && !isDatePassed(new Date(appointment.endTime))) {
            const higherCategoryItem = watch || getMainItemByValueCategory(itemList);
            if (higherCategoryItem.publicId !== mainItem?.publicId) {
                setNewCallableItem(higherCategoryItem);
            }

            const date = formatDateTimeRange(appointment.startTime, appointment.endTime);
            setScheduled(date);
        } else {
            setScheduled('');
        }

    }, [ appointment, mainItem ]);

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1) {
                console.log('event from schedule call');
                Ga.SendActionSuccess({
                    actionedObject2: 'shippingOptionsScheduled',
                    actionedValue2: 'callScheduled',
                    location1: 'sellerDashboard',
                    location2: 'shippingScreen',
                    location3: 'scheduleCallComponent',
                });
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const handleScheduleCall = () => {
        const link = Calendly.getCalendlyLinkByValueCategory(mainItem, user);
        Calendly.showPopupWidget(link, Calendly.calendlyParams(mainItem, user));
        Ga.SendActionNavigate({
            actionedObject: 'scheduleCallCtaBtn',
            location1: 'sellerDashboard',
            location2: 'shippingScreen',
            location3: 'scheduleCallComponent',
        });
    };

    return (
        <ScheduleCallWrapper>
            { !scheduled && (
                <CalendlySchedule>
                    <NotSureText variant="h5" maxWidth={ { xs: 220, sm: 150 } }>
                        Want to speak with our team?
                    </NotSureText>
                    <ScheduleCallBtn
                        data-automation="schedule-general-call"
                        variant="contained"
                        size="large"
                        onClick={ handleScheduleCall }
                        disableElevation
                    >
                        Schedule Call
                    </ScheduleCallBtn>
                </CalendlySchedule>
            ) }

            { scheduled && (
                <ScheduleCallData item={ mainItem } superItem={ newCallableItem } />
            ) }
        </ScheduleCallWrapper>
    );
};

export default ScheduleCall;
