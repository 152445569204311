import { Outlet, useLocation } from 'react-router-dom';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

const AnimatedOutlet = () => {
    const location = useLocation();
    return (
        <LayoutGroup>
            <AnimatePresence mode="wait" initial={ false }>
                <motion.div
                    key={ location.pathname }
                    transition={ { duration: 0.3 } }
                    layout="position"
                >
                    <Outlet />
                </motion.div>
            </AnimatePresence>
        </LayoutGroup>
    );
};

export default AnimatedOutlet;

