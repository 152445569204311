import React, { useEffect, useState } from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import dayjs from 'dayjs';
import { Typography } from '@worthy-npm/worthy-common-ui-components';

interface AuctionScheduledStepProps {
    item: Item;
}


const AuctionScheduledStep: React.FC<AuctionScheduledStepProps> = ({item}) => {
    const [ startAt ] = useState(item?.auctionDetails?.startTime);
    const [ countdown, setCountdown ] = useState('');

    useEffect(() => {
        if (!startAt) return;

        const targetDate = dayjs(startAt);
        const interval = setInterval(() => {
            const now = dayjs();
            const diff = targetDate.diff(now);

            if (diff <= 0) {
                clearInterval(interval);
                setCountdown('00:00:00');
                return;
            }

            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setCountdown(
                `${days ? String(days).padStart(2, '0')+':': ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [startAt]);

    return (
        <StyledStack>
            <DescriptionText>Visit your live auction page to watch.</DescriptionText>
            <CtaButton disabled>
                <Typography >
                    See Live Auction{startAt ? ` in ${countdown}` : ''}
                </Typography>
            </CtaButton>
        </StyledStack>
    );
};

export default AuctionScheduledStep;
