import React from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { getAppUrl } from 'src/config';
import { redirectTo } from 'src/helpers/common';

interface RejectAtTheDoorStepProps {
  item: Item;
}

const RejectAtTheDoorStep: React.FC<RejectAtTheDoorStepProps> = ({item}) => {
  const onclick = () => {
    redirectTo({url: `${getAppUrl()}/item/${item.publicId}?shipback=true`});
  };
  return (
    <StyledStack>
      <DescriptionText>Unfortunately upon arrival, we&apos;ve determined your item is not eligible for auction. Please contact our team for more information.
      </DescriptionText>
      <CtaButton onClick={onclick}>Initiate Return</CtaButton>
    </StyledStack>
  );
};

export default RejectAtTheDoorStep;
