import React, { useEffect, useState } from 'react';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { printLabel } from './PickupStep';
import { CircularProgress } from '@worthy-npm/worthy-common-ui-components';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';

interface DropOffStepProps {
    item: Item;
    onActionShow?: () => void;
}

const DropOffStep: React.FC<DropOffStepProps> = ({ item , onActionShow}) => {
    const [isClicked, setIsClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const isLabelReady = item?.schedulingDetails?.shipment?.shippingLabelUrl;
    const isDesktop = useDesktopVersion();

    useEffect(() => {
        if (sessionStorage.getItem(`prepare-${ item.publicId }`) && isDesktop) {
            setIsClicked(true);
        }
    }, [ item.publicId ]);

    const onclick = () => {
        onActionShow?.();
        setIsClicked(true);
        isDesktop && sessionStorage.setItem(`prepare-${ item.publicId }`, 'true');

        setTimeout(() => {
            window.dispatchEvent(
                new CustomEvent('prepare-set', { detail: { key: `prepare-${ item.publicId }` } }),
            );
        }, 0);
    };

    return (
        <StyledStack>
            <DescriptionText>
                Nearest FedEx: <br /> {item?.schedulingDetails?.nearestFedExLocations?.[0]}
            </DescriptionText>
            {isClicked ? (<CtaButton
                    onClick={ () => {
                                        setLoading(true);
                                        void printLabel(item).finally(() => setLoading(false));
                                    } }
                    disabled={ !isLabelReady || loading }
                >
                    { 'Print Shipping Labels' } {(!isLabelReady || loading) && <CircularProgress size={20} color="inherit" sx={{ml: 1}} />}
                </CtaButton>) :
                <CtaButton onClick={onclick}>Prepare for drop-off</CtaButton>
            }
        </StyledStack>
    );
};

export default DropOffStep;
