import { Box, IconButton, styled, Typography } from '@worthy-npm/worthy-common-ui-components';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

const ScheduleCallWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '24px 0',
  [theme.breakpoints.down('md')]: {
    margin: '0 16px 32px 16px',
  }
}));

const CalendlySchedule = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.action.disabled,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.divider}`,
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    flexDirection: 'column',
    padding: '24px 16px',
  },
}));

const NotSureText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h4.fontFamily,
  paddingRight: theme.spacing(1),
  letterSpacing: '-0.6px',
  lineHeight: '110%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
  }
}));

const CallScheduled = styled(Box, {
  shouldForwardProp: (prop) => ![ 'darkMode' ].includes(prop as string),
})<{darkMode: boolean}>(({ theme, darkMode }) => ({
  display: 'flex',
  backgroundColor: darkMode ? theme.palette.background.tertiary : theme.palette.background.paper,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '24px 16px',
  borderRadius: '8px',
  flexGrow: 1,
  minHeight: '96px',
  gap: '8px',
  position: 'relative',
  ...(darkMode && {
    border: `1px solid ${theme.palette.divider}`,
  }),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const ScheduleCallBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    marginTop: '16px',
  }
}));

const ScheduleDots = styled(IconButton, {
  shouldForwardProp: (prop) => ![ 'darkMode' ].includes(prop as string),
})<{ darkMode: boolean }>(({ theme, darkMode }) => ({
  width: '5px',
  height: '18px',
  cursor: 'pointer',
  position: 'absolute',
  top: '18px',
  right: '10px',
  color: darkMode ? theme.palette.secondary.main : '#D9D9D9',
}));


const CallDotsContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '17px',
  padding: '8px',
  color: theme.palette.text.primary,
}));

const CallDotsItem = styled(Box, {
  shouldForwardProp: (prop) => ![ 'cancel' ].includes(prop as string),
})<{cancel?: boolean}>(({ theme, cancel }) => ({
  display: 'flex',
  gap: '8px',
  color: cancel ? '#ED6855' : theme.palette.text.primary,
  alignItems: 'center',
  cursor: 'pointer',
}));


export {
  NotSureText,
  CallDotsItem,
  CallDotsContent,
  ScheduleCallWrapper,
  ScheduleCallBtn,
  CalendlySchedule,
  CallScheduled,
  ScheduleDots,
};
