import cookie from 'js-cookie';

const EXPERIMENTS_COOKIE_NAME = '_wor_exp';
const EXPERIMENT_SEPARATOR = '||';
const VARIANT_SEPARATOR = '::';

function getValue(name: string, experiments: string): string {
    return experiments
        .split(EXPERIMENT_SEPARATOR)
        .map((entry) => entry.split(VARIANT_SEPARATOR))
        .find(([ expName ]) => expName === name)?.[1] || '';
}

export function useExperimentVariant(name: string): number {
    const experiments = cookie.get(EXPERIMENTS_COOKIE_NAME) || '';
    const value = getValue(name, experiments);

    if (value.includes('var_1')) {
        return 1;
    }
    if (value.includes('var_2')) {
        return 2;
    }
    return 0;
}
