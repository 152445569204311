import { getMonolithUrl } from 'src/config';
import axios, { AxiosInstance } from 'axios';
import { ItemShippingType } from 'src/types/shipping.ts';
import { ADO } from 'src/types/ado.ts';

export interface GenerateShippingParams {
    itemPublicIds: string[];
    addressObj: {
        street: string;
        street2: string;
        city: string;
        state: string;
        zipcode: string;
    };
    user: {
        first_name: string;
        last_name: string;
    };
}

export interface GeneralApiRes {
    status: string;
}

export type DropOffLocations = Record<number, { name: string; address: string }>;

export interface SchedulePickupParams {
    itemPublicId: string;
    readyTimestamp: string;
    companyCloseTime: string;
    dropOffLocations: DropOffLocations;
    expectFullResponse?: boolean;
}

export interface ScheduleDropOffParams {
    itemPublicId: string;
    dropOffLocations: DropOffLocations;
    expectFullResponse?: boolean;
}

class WorthyService {
    private monolith: AxiosInstance;

    constructor() {
        this.monolith = axios.create({
            baseURL: getMonolithUrl(),
            withCredentials: true,
        });
    }

    async generateItemShippingByPublicID({
        itemPublicIds,
        addressObj,
        user,
    }: GenerateShippingParams): Promise<ItemShippingType['data']> {
        try {
            const { data } = await this.monolith.post<ItemShippingType['data']>(
                `/api/v2/sellers/item/create_shipping`,
                {
                    address: addressObj,
                    user: user,
                    auto_correct: true,
                    item_public_ids: itemPublicIds,
                },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async logUserAction(message: string, itemPublicId: string): Promise<GeneralApiRes['status']> {
        try {
            const { data } = await this.monolith.post<GeneralApiRes['status']>(
                `/api/v2/sellers/user_actions/item/${itemPublicId}/events`,
                {
                    page: 'listing_page',
                    id: itemPublicId,
                    version: '1.2',
                    component_key: message,
                },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async updateUserPhoneNumber(
        phoneNumber: string,
        userId: number,
    ): Promise<GeneralApiRes['status']> {
        try {
            const { data } = await this.monolith.post<GeneralApiRes['status']>(
                '/api/v2/sellers/user/update_missing_details',
                {
                    phone: phoneNumber,
                    user_id: userId,
                },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async scheduleDropOff({
        itemPublicId,
        dropOffLocations,
        expectFullResponse = false,
    }: ScheduleDropOffParams) {
        try {
            return this.monolith.post(`/api/v2/sellers/item/${itemPublicId}/drop_off`, {
                drop_off_locations: dropOffLocations,
                expectFullResponse: expectFullResponse,
            });
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async schedulePickup({
        itemPublicId,
        readyTimestamp,
        companyCloseTime,
        dropOffLocations,
        expectFullResponse = false,
    }: SchedulePickupParams): Promise<GeneralApiRes['status']> {
        try {
            const { data } = await this.monolith.post<GeneralApiRes['status']>(
                `/api/v2/sellers/item/${itemPublicId}/schedule_pickup`,
                {
                    ready_timestamp: readyTimestamp,
                    company_close_time: companyCloseTime,
                    drop_off_locations: dropOffLocations,
                    expectFullResponse: expectFullResponse,
                },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async ado(): Promise<ADO | Partial<ADO>> {
        try {
            const { data } = await this.monolith.get<ADO | Partial<ADO>>(`api/v2/sellers/ado`);
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async uploadImages(images: File[], itemId: string): Promise<{paths: string[]}> {
        try {
            const formData = new FormData();
            images.forEach((image, idx) => {
                formData.append(`file_${idx}`, image);
            });
            const { data } = await this.monolith.post<{paths: string[]}>(
                `/api/v2/sellers/item/${itemId}/upload_images`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async removeImages(files: string[], itemId: string): Promise<string[]> {
        try {
            const { data } = await this.monolith.post<string[]>(
                `/api/v2/sellers/item/${itemId}/remove_images`,
                { files },
            );
            return data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async addAnotherItem(itemId: string, itemType: string): Promise<void> {
        try {
            await this.monolith.post<string[]>(`/api/v2/sellers/item/${itemId}/add_another_item`, {
                itemType,
            });
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async updateProperty(
        itemId: string,
        name: string,
        value: string,
        isGemstone = false,
    ): Promise<void> {
        try {
            await this.monolith.post<string[]>(`/api/v2/sellers/item/${itemId}/update_property`, {
                name,
                value,
                gemstone: isGemstone,
            });
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async updateCertificate(
        itemId: string,
        certificateNumber: string,
        certificateProvider = 'gia',
    ): Promise<void> {
        try {
            await this.monolith.post<string[]>(
                `/api/v2/sellers/item/${itemId}/update_certificate_data`,
                {
                    data: {
                        certificate: certificateProvider,
                        certificate_number: certificateNumber,
                    },
                },
            );
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async updateCaratWeight(itemId: string, caratWeight: string): Promise<void> {
        try {
            await this.monolith.post<string[]>(
                `/api/v2/sellers/item/${itemId}/update_carat_weight`,
                {
                    carat_weight: caratWeight,
                },
            );
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async submitSurveyAnswers(itemId: string, value: string, question: string): Promise<void> {
        try {
            await this.monolith.post<string[]>(`/api/v2/sellers/user_actions/item/${itemId}/survey`,
                {
                    component_key: question,
                    objectId: itemId,
                    objectType: 'Item',
                    page: 'survey',
                    value: [value],
                });
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }

    async abortItem(itemId: string): Promise<void> {
        try {
            await this.monolith.get<string[]>(`/api/v2/sellers/item/${ itemId }/online_abort_item`);
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }
}

export const worthyService = new WorthyService();
