import {
    Box,
    Typography,
} from '@worthy-npm/worthy-common-ui-components';
import {
    HiwImgContent, HiwPcStack, HiwStep, HiwStepTitle,
    HiwWrapper,
} from 'src/styles/hiw/hiw.styles.tsx';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';
import AccordionStepper from '../AccordionStepper/AccordionStepper';
import { ReactNode } from 'react';


export interface HIWProps {
    steps: { title: string | ReactNode; description: string | ReactNode, img: string }[];
    title: string;
    moreInfoLink?: string;
    previewTitle?: string;
    previewText?: string;
    currentStepIndex: number;
}

const HIW = ({ title, steps, currentStepIndex, previewTitle, previewText }: HIWProps) => {
    const isPc = useDesktopVersion();

    return (
        <HiwWrapper
            component="section"
            elevation={ 0 }
        >
            {
                !isPc && (
                    <AccordionStepper
                        steps={ steps }
                        title={ title }
                        currentStepIndex={ currentStepIndex }
                        previewTitle={ previewTitle }
                        previewText={ previewText }
                    />
                )
            }
            {
                isPc && (
                    <>
                        <Typography
                            variant="h3"
                            color="text.secondary"
                            textAlign={ 'center' }
                            mb={ 1 }
                        >
                            { title }
                        </Typography>
                        <HiwPcStack>
                            {
                                steps.map((step, index) => (
                                    <HiwStep
                                        key={ index }
                                    >
                                        <HiwImgContent
                                            darken={ index > currentStepIndex }
                                            highlight={ index === currentStepIndex }
                                            bgImage={ step.img }
                                        >
                                            <Typography
                                                variant="h3"
                                                position="relative"
                                            >
                                                { index + 1 }.
                                            </Typography>

                                        </HiwImgContent>
                                        <Box
                                            position="relative"
                                        >
                                            <HiwStepTitle
                                                variant="h4"
                                            >
                                                { step.title }
                                            </HiwStepTitle>
                                            <Typography
                                                variant="caption"
                                                color="background.paper"
                                            >
                                                { step.description }
                                            </Typography>
                                        </Box>

                                    </HiwStep>
                                ))
                            }

                        </HiwPcStack>

                    </>

                )
            }


        </HiwWrapper>
    );
};

export default HIW;
