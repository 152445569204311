import { useState } from 'react';
import {
  Typography,
  Unstable_Grid2 as Grid,
  Stack,
  AccordionSummary,
  AccordionDetails,
  Button,
  Icon, Box, Divider, IconButton,
} from '@worthy-npm/worthy-common-ui-components';
import ItemPhoto from 'src/components/ItemPhoto/ItemPhoto';
import {  AlertCaption, StyledAccordion, ValidItemCheckbox } from './ItemCardDetails.styles';
import _ from 'lodash';
import { pendingSchedulingStates } from 'src/constants/item.constants';
import { pendingReason } from 'src/constants/itemCard.constants';
import { Item } from 'src/types/item.ts';
import CertificateModal from 'src/components/CertificateModal/CertificateModal.tsx';
import { disablePhotoUploadState } from 'src/helpers/item';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getSchedulingCurrentStep } from 'src/store/schedulingSlice';
import ClickableHelpTooltip from 'src/components/ClickableHelpTooltip/ClickableHelpTooltip.tsx';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { ItemGroups } from 'src/constants/itemCard.constants.tsx';
import { abortItem, isItemLoading } from 'src/store/itemsSlice';
import { useLocation } from 'react-router-dom';
import { sdRoutes } from 'src/routes.tsx';

interface ItemCardDetailsProps {
  item: Item;
  onCheckboxChange?: (item: Item, isChecked: boolean) => void;
}

const ItemCardDetails = ({ item, onCheckboxChange }: ItemCardDetailsProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoading = useAppSelector((state) => isItemLoading(state, item.publicId));

  const { itemDetails } = item;
  const filteredProperties = _.omit(itemDetails, [ 'certificate', 'certificateId', 'certificateType' ]);
  const isPendingState = pendingSchedulingStates.includes(item.status);
  const alertForPending = pendingReason(item.status, item.photos.length > 0, item.group);
  const currentStep = useAppSelector(getSchedulingCurrentStep);
  const enableCheckbox = item.group === ItemGroups.Valid && onCheckboxChange;
  const itemsWithGia = [ 'ring', 'loose diamond' ];

  const [ uploadCertificateOpen, setUploadCertificateOpen ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);

  const itemProperties = _.chain(filteredProperties)
                          .thru(obj => {
                            if ((obj.carat || obj.caratRangeTo) && item.itemDescription.includes('CT')) {
                              const beforeCT = item.itemDescription.split('CT')[0];
                              if (beforeCT.includes('-')) {
                                obj.carat = obj.caratRangeTo
                                    ? `${ obj.carat || '' } - ${ obj.caratRangeTo }`.trim()
                                    : obj.carat;
                              }
                            }

                            if (obj.caratClassification) {
                              obj.stoneType = obj.caratClassification;
                            }

                            return _.omit(obj, [ 'caratRangeTo', 'caratClassification' ]);
                          })
                          .value();

  const handleCertificateModalClose = () => {
    setUploadCertificateOpen(false);
  };

  const handleGiaButtonClick = () => {
    setUploadCertificateOpen(true);
  };

  const genDescription = (value: string) => {
    if (!isNaN(Number(value))) {
      return !value ? '--' : value;
    } else {
      return value.includes('-') ? value : _.startCase(value);
    }
  };

  const deleteItem = () => {
    dispatch(abortItem(item.publicId));
  };

  const deleteItemComponent = () => {
    return (
        <>
          <Grid xs={ 12 }>
            <Divider id="details-divider" flexItem orientation="horizontal" />
          </Grid>
          {
            openDelete ? (
                <Grid xs={ 12 } marginTop="14px">
                  <Typography
                      textAlign="center"
                      variant="body1"
                      fontWeight="700"
                  >
                    Are you sure you want to delete this item?
                  </Typography>
                  <Stack
                      direction={ 'row' }
                      gap={ 1 }
                      maxWidth="408px"
                      justifyContent="center"
                      margin="16px auto"
                  >
                    <Button
                        variant="outlined"
                        sx={ {
                          border: `1px solid ${ worthyShineTheme.palette.text.primary }`,
                          color: worthyShineTheme.palette.text.primary,
                        } }
                        disabled={ isLoading }
                        fullWidth
                        onClick={ () => setOpenDelete(false) }
                    >
                      Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={ { backgroundColor: '#ED6855' } }
                        disabled={ isLoading }
                        fullWidth
                        onClick={ deleteItem }
                    >
                      Delete
                    </Button>
                  </Stack>
                </Grid>
            ) : (
                <Grid xs={ 12 }>
                  <IconButton
                      disableRipple
                      onClick={ () => setOpenDelete(true) }
                      sx={ {
                        display: 'flex',
                        alignItems: 'flex-end',
                        textAlign: 'center',
                        color: '#ED6855',
                        marginTop: '9px',
                        gap: '8px',
                      } }
                  >
                    <Icon.TrashWithCross />
                    <Typography variant="body1" fontWeight="700">Delete item</Typography>
                  </IconButton>
                </Grid>
            )
          }
        </>
    );
  };

  const certificateComponent = () => {
    if (itemsWithGia.includes(item.itemType?.toLowerCase())) {
      return item?.itemDetails?.certificateId ? (
          <Typography
              variant="caption"
              fontSize={ 'small' }
          >
            Report #{ item.itemDetails.certificateId }
          </Typography>
      ) : (
          <Stack
              direction={ 'row' }
              alignItems={ 'center' }
              gap={ 0.5 }
          >
            <Button
                onClick={ handleGiaButtonClick }
                sx={ { padding: 0 } }
            >
              <Typography
                  variant="caption"
                  fontSize={ 'small' }
                  color="tertiary.main"
              >
                + Upload GIA Report
              </Typography>
            </Button>
            <ClickableHelpTooltip
                title={ 'If you have a report from GIA (Gemological Institute of America), click below to enter your report number. If you have a report from another grading lab, upload your report as an image above.' }
            />

          </Stack>
      );
    }
  };

  return (
    <Grid
      container
      width={'100%'}
      rowGap={1.5}
    >
      {/* {alertForPending && (
        <Grid xs={12}>
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
          >
            <Typography
              variant="caption"
              color={'error.main'}
            >
              {alertForPending.title}
            </Typography>
            <ClickableHelpTooltip
                title={alertForPending.description}
            />
          </Stack>
        </Grid>
      )} */}
      <Grid xs={12}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Stack
            justifyContent={'space-between'}
            height={'100%'}
            maxWidth="303px"
          >
            <Stack gap={{xs: 0.5, sm: 1}}>
            {alertForPending && (
        <Grid xs={12}>
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
          >
            <AlertCaption
              variant="caption"
              color={'error.main'}
            >
              {alertForPending.title}
            </AlertCaption>
            <ClickableHelpTooltip
                title={alertForPending.description}
            />
          </Stack>
        </Grid>
      )}
            <Stack
              direction={'row'}
              gap={1}
            >
              {enableCheckbox && (
                <ValidItemCheckbox
                  defaultChecked
                  disableRipple
                  disabled={currentStep !== 0}
                  onChange={(e, checked: boolean) => onCheckboxChange(item, checked)}
                />
              )}
              <Typography
                variant="body1"
                fontSize={'1.1rem'}
                color={isPendingState ? 'divider' : 'text.primary'}
                pr={2}
              >
                {item.itemDescription}
              </Typography>
            </Stack>
            </Stack>
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              color={'secondary.main'}
              flexWrap={'wrap'}
            >
              <Typography
                variant="caption"
                fontSize={'small'}
              >
                ID #{item.publicId}
              </Typography>
              {
                  itemsWithGia.includes(item.itemType?.toLowerCase()) && (
                      <Box sx={{borderRight: `1.5px solid ${worthyShineTheme.palette.action.disabled}`, height: '16px'}}/>
                  )
              }
              { certificateComponent() }
              {uploadCertificateOpen && (
                  <CertificateModal
                    open={uploadCertificateOpen}
                    onClose={ handleCertificateModalClose }
                    itemPublicId={ item.publicId }
                  />
                )}
              </Stack>
          </Stack>
          <ItemPhoto
            disableUpload={disablePhotoUploadState(item.status)}
            itemType={item.itemType?.toLowerCase()}
            itemPublicId={item.publicId}
            photosUrl={item.photos}
          />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <StyledAccordion
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<Icon.ExpandMoreShineIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              variant="body1"
              fontWeight={'600'}
              color={'secondary.main'}
            >
              Item information
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={ { p: 1, pt: 0 } }>
            <Grid container>
              {Object.entries(itemProperties).map(([key, value]) => (
                <Grid
                  xs={12}
                  md={6}
                  key={key}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    padding={1}
                    gap={1}
                  >
                    <Typography
                      variant="body1"
                      color={'secondary.main'}
                      fontSize={'14px'}
                      width={'104px'}
                    >
                      {_.startCase(key)}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={'500'}
                      fontSize={'14px'}
                    >
                          { genDescription(value) }
                        </Typography>
                      </Stack>
                    </Grid>
                )) }
                { location.pathname.includes(sdRoutes.shipping) && deleteItemComponent() }
              </Grid>
            </AccordionDetails>
          </StyledAccordion>
        </Grid>

      </Grid>
  );
};

export default ItemCardDetails;
