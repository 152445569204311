import {
    auctionStates,
    WorkflowStatus,
    processingStates,
    shippingStates,
    SchedulingType,
} from './item.constants';
import PickupStep from 'src/components/ItemCard/StepsActions/PickupStep';
import { FC, ReactNode } from 'react';
import DropOffStep from 'src/components/ItemCard/StepsActions/DropOffStep';
import InTransitStep from 'src/components/ItemCard/StepsActions/InTransitStep';
import PrepForSaleStep from 'src/components/ItemCard/StepsActions/PrepForSaleStep';
import RejectAtTheDoorStep from 'src/components/ItemCard/StepsActions/RejectAtTheDoorStep';
import ReadyForAuctionStep from 'src/components/ItemCard/StepsActions/ReadyForAuctionStep';
import AbortedStep from 'src/components/ItemCard/StepsActions/AbortedStep';
import AuctionEndedStep from 'src/components/ItemCard/StepsActions/AuctionEndedStep';
import LiveAuctionStep from 'src/components/ItemCard/StepsActions/LiveAuctionStep';
import { Item } from 'src/types/item.ts';
import PickUpSettingMenu from 'src/components/ItemCard/StepsActions/PickupSettingMenu';
import GetOfferStep from 'src/components/ItemCard/StepsActions/GetOfferStep';
import AuctionScheduledStep from 'src/components/ItemCard/StepsActions/AuctionScheduledStep';
import { PrepareForPickupDropOffDesktop } from 'src/views/PrepareForPickupDropOffDesktop';
import ArrivedStep from 'src/components/ItemCard/StepsActions/ArrivedStep';
import { Icon, Typography } from '@worthy-npm/worthy-common-ui-components';
import ReturnPendingStep from 'src/components/ItemCard/StepsActions/ReturnPengingStep.tsx';

export const pendingReason = (status: Item['status'], isPhoto: boolean, group: string|undefined) => {

    switch (status) {
        case WorkflowStatus.PENDING_APPROVAL:
            if (group === ItemGroups.Valid ) return null;
            return {
                title: 'Approval pending',
                description: 'Our experts are currently reviewing your photo(s). Upon approval, your FedEx shipping options will become available.',
            };

        case WorkflowStatus.INSURANCE_APPROVAL_REQUIRED:
            return {
                title: 'Contact Us',
                description: 'It looks like you have an especially valuable item; therefore, our team would like to personally arrange insurance and shipping. Please contact us to discuss your preferred shipping option.',
            };
        case WorkflowStatus.PENDING:
            if (group === ItemGroups.Valid ) return null;
            if (!isPhoto) {
                return {
                    title: 'Photo required',
                    description: 'Please add a clear photo of your item to continue with the selling process. Our experts will review your photo(s) and once approved, your FedEx shipping options will become available.',
                };
            }
            return {
                title: 'Approval pending',
                description: 'Our experts are currently reviewing your photo(s). Upon approval, your FedEx shipping options will become available.',
            };
        case WorkflowStatus.PENDING_IMAGE_UPLOAD:
            if (group === ItemGroups.Valid ) return null;
            return {
                title: 'Photo required',
                description: 'Please add a clear photo of your item to continue with the selling process. Our experts will review your photo(s) and once approved, your FedEx shipping options will become available.',
            };
    }
    return null;
};

export const ItemGroups = {
  Pending: 'Pending',
  Valid: 'Valid',
} as const;

const AlertStatuses = [
    WorkflowStatus.REJECTED_AFTER_AUCTION,
    WorkflowStatus.REJECTED_IN_PREPARATION,
    WorkflowStatus.QUICK_SALE,
    WorkflowStatus.RETURNED,
];

const ShippingStatusesToIndex:Partial<Record<WorkflowStatus, number>> = {
    [WorkflowStatus.SCHEDULING]: 0,
    [WorkflowStatus.SHIPPING_SCHEDULED]: 1,
    [WorkflowStatus.IN_TRANSIT]: 2,
};
const ProcessingStatusesToIndex:Partial<Record<WorkflowStatus, number>>  = {
    [WorkflowStatus.ARRIVED]: 1,
    [WorkflowStatus.PREP_FOR_SALE]: 2,
    [WorkflowStatus.REJECTED_IN_PREPARATION]: 2,
    [WorkflowStatus.QUICK_SALE]: 2,
    [WorkflowStatus.RETURNED]: 1,
};

const AuctionStatusesToIndex:Partial<Record<WorkflowStatus, number>> = {
    [WorkflowStatus.READY_FOR_AUCTION]: 1,
    [WorkflowStatus.LIVE_AUCTION]: 2,
    [WorkflowStatus.AUCTION_ENDED]: 3,
    [ WorkflowStatus.REJECTED_AFTER_AUCTION]: 3,
    [WorkflowStatus.DEAL]: 3,
    [WorkflowStatus.AUCTION_SCHEDULED]: 1,
    [WorkflowStatus.RETURN_PENDING]: 3,
};

export const ProgreeBarOptions = ({
                                      item,
                                  }: {
    item: Item;
}): { color: string; steps: string[]; title: string; currentStepIndex: number; alert: boolean } => {
    let steps: string[] = [];
    let title = '';
    let color = '';
    const alert = AlertStatuses.includes(item.status);
    let currentStepIndex = -1;

    if (shippingStates.includes(item.status)) {
        color = '#AB7C8A';
        steps = [
            item?.schedulingDetails?.type === SchedulingType.PICKUP ? 'Scheduled' : 'Confirmed',
            item?.schedulingDetails?.type === SchedulingType.PICKUP ? 'Pickup' : 'Drop off',
            'In transit',
            'Delivered',
        ],
            title = 'Shipping';
        currentStepIndex = ShippingStatusesToIndex[item.status] ?? -1;
    } else if (processingStates.includes(item.status)) {
        color = '#DCA961';
        steps = [
            'Arrived',
            'Prep for sale',
            'Ready for auction',
        ];
        title = 'Processing';
        currentStepIndex = ProcessingStatusesToIndex[item.status] ?? -1;
    } else if (auctionStates.includes(item.status)) {
        color = '#73A0F7';
        steps = [
            'Ready for auction',
            'Auction Started',
            'Auction Ended',
        ];
        title = 'Auction';
        currentStepIndex = AuctionStatusesToIndex[item.status] ?? -1;
    } else if (item.status === WorkflowStatus.RETURN_PENDING) {
        color = '#73A0F7';
        steps = [
            'Ready for auction',
            'Auction Started',
            'Auction Ended',
        ];
        title = 'Auction';
        currentStepIndex = 3;
    }
    return {
        alert,
        color,
        steps,
        title,
        currentStepIndex,
    };
};
interface StepInfoObjectResultI {
    title: string | ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ActionComponent?: FC<any>  | undefined;
    ActionMenuComponent?: FC<{items: Item[]}> | undefined;
    ActionTriggeredComponent?: ({ item, onClose }: {item: Item, onClose: ()=> void}) => JSX.Element | undefined;
}

export const StepInfoObject = ({
                                   items,
                               }: {
    items: Item[];
}): StepInfoObjectResultI => {
    const item = items[0];
    const alert = AlertStatuses.includes(item.status);
    const alertIcon = alert  ? <Typography component={'span'} color={'text.primary'} sx={{verticalAlign: 'sub',fontSize: '1.3em'}}><Icon.ReportProblem  fontSize='inherit'/></Typography>: '';
    let result:StepInfoObjectResultI = {
        title: item.status,
        ActionComponent: undefined,
        ActionMenuComponent: undefined,
        ActionTriggeredComponent: undefined,
    };
    switch (item.status) {
        case WorkflowStatus.SHIPPING_SCHEDULED:
            result = item.schedulingDetails?.type === SchedulingType.PICKUP
                ? {
                    title: 'Pickup successfully scheduled',
                    ActionComponent: PickupStep,
                    ActionMenuComponent: PickUpSettingMenu,
                    ActionTriggeredComponent: PrepareForPickupDropOffDesktop
                }
                : {
                    title: 'FedEx drop off confirmed',
                    ActionComponent: DropOffStep,
                    ActionMenuComponent: PickUpSettingMenu,
                    ActionTriggeredComponent: PrepareForPickupDropOffDesktop
                };
                break;
        case WorkflowStatus.IN_TRANSIT:
            result = {
                title: 'Your item is on the way',
                ActionComponent: InTransitStep,
            };
            break;
        case WorkflowStatus.PREP_FOR_SALE:
            result = {
                title: 'We are preparing your items for auction',
                ActionComponent: PrepForSaleStep,
            };
            break;
        case WorkflowStatus.QUICK_SALE:
            result = {
                title: 'Item eligible for direct buy',
                ActionComponent: GetOfferStep,
            };
            break;
        case WorkflowStatus.ARRIVED:
            result = {
                title: 'We’ve received your item',
                ActionComponent: ArrivedStep,
            };
            break;
        case WorkflowStatus.REJECTED_IN_PREPARATION:
            result = {
                title: 'Item not eligible for auction',
                ActionComponent: RejectAtTheDoorStep,
            };
            break;
        case WorkflowStatus.READY_FOR_AUCTION:
            result = {
                title: 'Your item is ready for auction!',
                ActionComponent: ReadyForAuctionStep,
            };
            break;
        case WorkflowStatus.AUCTION_SCHEDULED:
            result = {
                title: 'Auction scheduled!',
                ActionComponent: AuctionScheduledStep,
            };
            break;
        case WorkflowStatus.LIVE_AUCTION:
            result = {
                title: 'Your auction has started!',
                ActionComponent: LiveAuctionStep,
            };
            break;
        case WorkflowStatus.AUCTION_ENDED:
            result = {
                title: 'Your auction has ended',
                ActionComponent: AuctionEndedStep,
            };
            break;
        case WorkflowStatus.DEAL:
            result = {
                title: 'Your auction has ended',
                ActionComponent: AuctionEndedStep,
            };
            break;
        case WorkflowStatus.REJECTED_AFTER_AUCTION:
            result = {
                title: 'Your auction has ended',
                ActionComponent: AbortedStep,
            };
            break;
        case WorkflowStatus.RETURN_PENDING:
            result = {
                title: 'Return in progress',
                ActionComponent: ReturnPendingStep,
            };
            break;
        default:
            result = {
                title: item.status,
            };
    }

    if(alertIcon) {
        result.title = <>{alertIcon} {result.title}</>
    }
    return result;
};
