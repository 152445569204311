import { useEffect } from 'react';
import Header from 'src/layout/nav/header.tsx';
import { Box, Drawer, styled } from '@worthy-npm/worthy-common-ui-components';
import Navigation from 'src/layout/nav/navigation.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getSchedulingCurrentStep, isSchedulingOpen, setOpenScheduling } from 'src/store/schedulingSlice';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp';
import Banner from 'src/components/banner/Banner.tsx';
import { useDesktopVersion } from 'src/helpers/deviceSize';
import { Dialog } from '@mui/material';
import ScrollToTop from 'src/components/ScrollToTop.tsx';
import AnimatedOutlet from 'src/layout/baseLayout/AnimatedOutlet.tsx';


const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
}));

const StyledModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
        minWidth: 360,
        maxWidth: 358,
        minHeight: 460,
        backgroundColor: theme.palette.background.default,
    },
}));

const BaseLayout = () => {
    const dispatch = useAppDispatch();
    const openReschedule = useAppSelector(isSchedulingOpen);
    const currentStep = useAppSelector(getSchedulingCurrentStep);
    const isDesktop = useDesktopVersion();

    useEffect(() => {
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }
    }, []);

    return (
        <Box>
            <ScrollToTop />
            <Banner />
            <Header />
            <Navigation />
            <Box>
                <AnimatedOutlet />
            </Box>
            {
                isDesktop ? <StyledModal
                        open={ openReschedule }
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                        onClose={ () => {
                            if (currentStep === 0) {
                                dispatch(setOpenScheduling(false));
                            }
                        } }
                    >
                        <SchedulingPopUp isPC={ true } />
                    </StyledModal> :
                    <StyledDrawer
                        anchor="bottom"
                        open={ openReschedule }
                        onClose={ () => {
                            if (currentStep === 0) {
                                dispatch(setOpenScheduling(false));
                            }
                        } }>
                        <SchedulingPopUp isPC={ false } />
                    </StyledDrawer>
            }
        </Box>
    );
};

export default BaseLayout;
