  import styled from '@emotion/styled';
import { Accordion, Box, Stack } from '@worthy-npm/worthy-common-ui-components';
import Button from '@worthy-npm/worthy-common-ui-components/components/Button';

const StyledFAqAccordion = styled(Accordion)<{ expanded?: boolean }>(({ theme, expanded }) => ({
  color: expanded ? theme.palette.common.white : theme.palette.tertiary.main,
  background: 'transparent',
  boxShadow: 'none',
  '&:before': {
    backgroundColor: theme.palette.tertiary.main,
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',
  },
}));

const FAQWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.secondary,
  padding: theme.spacing(11, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3),
  },
}));
const StackWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 1200,
  margin: '0 auto',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
}));

const MoreButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.highlight.main,
  background: theme.palette.background.default,
  '&:hover': {
    background: `${theme.palette.background.default}de`,
  },
}));

export { StyledFAqAccordion, FAQWrapper, StackWrapper, MoreButton };
