import { Icon, IconButton, Stack, Typography } from '@worthy-npm/worthy-common-ui-components';
import { HIWProps } from '../AccordionStepper/AccordionStepper';
import { Item } from 'src/types/item.ts';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface DesktopStepperProps {
    steps: HIWProps['steps'];
    title: HIWProps['title'];
    item: Item;
}

const DesktopStepper = ({ steps, title, item }: DesktopStepperProps) => {
    const [ close, setClose ] = useState(true);

    useEffect(() => {
        const handlePrepareSet = (e: CustomEvent<{ key: string;}>) => {
            if (e.detail?.key === `prepare-${ item.publicId }`) {
                setClose(false);
            }
        };

        window.addEventListener('prepare-set', handlePrepareSet as EventListener);
        return () => window.removeEventListener('prepare-set', handlePrepareSet as EventListener);
    }, [ item.publicId ]);


    return (
        <Stack direction={ 'row' } position={ 'relative' } justifyContent={ 'space-between' } alignItems="center">
            <Stack justifyContent={ 'space-around' }>
                <Typography
                    p={ close ? 0 : 2 }
                    pl={ 0 }
                    variant={ close ? 'h4' : 'h3' }
                    textAlign={ 'center' }
                    maxWidth={ close ? '100%' : 200 }
                    margin={ '0 auto' }
                    fontSize={ close ? '20px !important' : 'initial' }
                >
                    { title }
                </Typography>
            </Stack>
            <Stack direction={ 'row' } gap={ 4 } alignItems={ 'start' }>
                { steps.map((step, index) => (
                    <Stack key={ index } gap={ 1 } maxWidth={ 150 } id="steps">
                        <Stack
                            direction={ 'row' }
                            alignItems={ 'flex-end' }
                            gap={ close ? 2 : 0 }
                        >
                            <Typography component="span" display="flex" alignItems="center">
                                <Typography
                                    variant={ close ? 'h4' : 'h3' }
                                    fontSize={ close ? '24px' : 'initial' }
                                    component="span"
                                >
                                    { index + 1 }
                                </Typography>
                                {
                                    close && <Typography variant="caption" component="span"
                                                         marginLeft="10px">{ step.activeTitle || step.title }</Typography>
                                }
                            </Typography>
                            {
                                index !== steps.length - 1 && (
                                    <Typography sx={ { margin: '0 auto', color: 'tertiary.main' } }>
                                        <Icon.NextArrowIcon sx={ close ? { width: '2rem' } : { width: '3rem' } }
                                                            color="inherit" />
                                    </Typography>
                                )
                            }

                        </Stack>
                        <AnimatePresence>
                            { !close && (
                                <motion.div
                                    key="step-description"
                                    initial={ { opacity: 0, y: -10 } }
                                    animate={ { opacity: 1, y: 0 } }
                                    exit={ { opacity: 0, y: -10 } }
                                    transition={ { duration: 0.3 } }
                                >
                                    <Stack direction="column" gap={ 1 } id="step-description">
                                        <Typography variant="body1" fontWeight="bold">
                                            { step.title }
                                        </Typography>
                                        <Typography variant="body1">{ step.description }</Typography>
                                    </Stack>
                                </motion.div>
                            ) }
                        </AnimatePresence>

                    </Stack>
                )) }
            </Stack>

            <IconButton
                onClick={ () => setClose(!close) }
                sx={ {
                    alignSelf: 'flex-start',
                    color: 'secondary.main',
                    width: '12px',
                    height: '6px',
                    fontSize: '14px',
                    transform: close ? 'rotate(0deg)' : 'rotate(180deg)',
                } }
                disableRipple
            >
                <Icon.ExpandMoreShineIcon color="inherit" fontSize="inherit" />
            </IconButton>


        </Stack>);
};
export default DesktopStepper;
