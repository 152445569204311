import styled from '@emotion/styled';
import { Box, FileInput, Preview } from '@worthy-npm/worthy-common-ui-components';
import { CtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '328px',
  minHeight: '472px',
  maxWidth: '50vw',
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: '16px',
  gap: '12px',
}));

const Header = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    align-self: stretch;
`;

const Content = styled(Box, { shouldForwardProp: (prop) => prop !== 'photos' })<{
  photos?: boolean;
}>(({ theme, photos }) => ({
  display: 'flex',
  borderRadius: '16px',
  flex: '1 1 auto',
  flexWrap: 'wrap',
  gap: '16px',
  justifyContent: photos ? 'initial' : 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const SaveWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 auto',
  width: '100%',
  gap: '32px',
  marginTop: '12px',
  [theme.breakpoints.down('md')]: {
    gap: '0',
  }
}));

const ModalUploadPhotoBtn = styled(CtaBtn)(({theme}) => ({
  background: theme.palette.primary.main,
  alignSelf: 'stretch',
  fontSize: '18px',
  flex: '1',
  padding: '8px',
  alignItems: 'center',
  maxHeight: '47px',
  minWidth: '92px',
  '&:disabled': {
    color: '#919AA7'
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'flex-end'
  },
}));

const LargePreview = styled(Preview)(({ theme }) => ({
  width: '100%',
  height: '194px',
  flex: '1 1 auto',
  '& .preview': {
    borderRadius: '4px',
  },

  [theme.breakpoints.down('md')]: {
    flex: '0 1 auto',
  },
}));

const SmallImagePreviewWrap = styled(Box)`
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: center;
    width: 301px;
    align-items: center;
    align-content: center;
    gap: 8px 12px;
`;

const SmallPreview = styled(Preview, { shouldForwardProp: (prop) => prop !== 'largePreviewImg' })<{
  largePreviewImg?: boolean;
}>(({ theme, largePreviewImg }) => ({
  maxWidth: '92px',
  width: '100%',
  height: '80px',
  borderRadius: '4px',
  border: largePreviewImg ? `3px solid ${theme.palette.text.primary}` : 'none',
  background:
    'radial-gradient(90.31% 59.72% at 99.31% 4.86%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.495676) 6.67%, rgba(0, 0, 0, 0.482245) 13.33%, rgba(0, 0, 0, 0.45917) 20%, rgba(0, 0, 0, 0.426294) 26.67%, rgba(0, 0, 0, 0.384113) 33.33%, rgba(0, 0, 0, 0.334058) 40%, rgba(0, 0, 0, 0.278654) 46.67%, rgba(0, 0, 0, 0.221346) 53.33%, rgba(0, 0, 0, 0.165942) 60%, rgba(0, 0, 0, 0.115887) 66.67%, rgba(0, 0, 0, 0.0737057) 73.33%, rgba(0, 0, 0, 0.0408299) 80%, rgba(0, 0, 0, 0.017755) 86.67%, rgba(0, 0, 0, 0.0043236) 93.33%, rgba(0, 0, 0, 0) 100%)',

  '& div': {
    background: 'transparent',
    margin: '3px 3px',
    color: '#DADADA',
  },

  '& .preview': {
    borderRadius: 0,
  },
}));

const FileInputWrap = styled(FileInput, { shouldForwardProp: (prop) => prop !== 'photos' })<{
  photos?: boolean;
}>(({ theme }) => ({
  padding: '20px 16px',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: theme.palette.action.disabled,
  flexDirection: 'column',
  height: '80px',
  maxWidth:'92px',
  gap: theme.spacing(1),
}));

export {
  ModalUploadPhotoBtn,
  SaveWrapper,
  SmallImagePreviewWrap,
  SmallPreview,
  LargePreview,
  ModalContainer,
  Header,
  Content,
  FileInputWrap,
};
