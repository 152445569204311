import {
  Box,
  FormControlLabel,
  Paper,
  styled,
  Typography
} from '@worthy-npm/worthy-common-ui-components';
import TextField from '@worthy-npm/worthy-common-ui-components/components/TextField';
import theme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme/worthyShineTheme';

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  alignSelf: 'stretch',
}));

export const TextInput = styled(TextField)(() => ({
  padding: '0px',
}));

export const RadioWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: '8px',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const FormControl = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: '-0.28px',
  },
}));



export const Location = styled('li')(({theme}) => ({
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1,1.5,1.5,0),
  cursor: 'pointer',
  gap: theme.spacing(1),
  transition: 'background-color 0.3s ease',
  backgroundColor: 'inherit',
  '&:hover, :active': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const Street = styled(Typography)(() => ({
  color: theme.palette.text.primary,
}));

export const State = styled(Typography)(() => ({
  color: theme.palette.secondary.main,
  fontSize: 12,
  fontWeight: 500,
}));

export const LocationPoper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[1],
}));