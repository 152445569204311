import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CtaButton, DescriptionText, StyledStack } from './StepsActions.styles';
import { Item } from 'src/types/item.ts';
import { CircularProgress } from '@worthy-npm/worthy-common-ui-components';
import { onPrintShippingLabelClick } from './StepsAction.helpers';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';

export const printLabel =  (item: Item) => {
  const pdfLabels = window.open('', '_blank');
  pdfLabels?.document.write('Loading Labels...');

  return  pdfLabels ? onPrintShippingLabelClick(item).then((url) => {
    if(url){
      pdfLabels.location.href = url;
    }else{
      pdfLabels?.document.write('Error loading labels');
    }
  }).catch( (err) => {
    console.error('Error loading labels', err);
    pdfLabels?.document.write('Error loading labels');
  }): Promise.resolve();
};

interface PickupStepProps {
  item: Item;
  onActionShow?: () => void;
}


const PickupStep: React.FC<PickupStepProps> = ({ item, onActionShow }) => {
  const isDesktop = useDesktopVersion();

  const [isClicked, setIsClicked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const isLabelReady = item?.schedulingDetails?.shipment?.shippingLabelUrl;
  const startDate = item?.schedulingDetails?.localStartTimeString;
  const endDate = item?.schedulingDetails?.localEndTimeString;
  const startDateString = startDate ? dayjs(startDate.split('+')[0]).format('MMMM. D, YYYY, H') : "";
  const endDateString = endDate ? dayjs(endDate.split('+')[0]).format(' - Ha') : '';

  useEffect(() => {
    if (sessionStorage.getItem(`prepare-${ item.publicId }`) && isDesktop) {
      setIsClicked(true);
    }
  }, [ item.publicId ]);

  const onclick = () => {
    onActionShow?.();
    setIsClicked(true);
    isDesktop && sessionStorage.setItem(`prepare-${ item.publicId }`, 'true');

    setTimeout(() => {
      window.dispatchEvent(
          new CustomEvent('prepare-set', { detail: { key: `prepare-${ item.publicId }` } }),
      );
    }, 0);
  };

  return (
      <StyledStack gap={ 1.5 }>
        <DescriptionText>
          { startDateString + endDateString }
          <br />{ item?.schedulingDetails?.address }
        </DescriptionText>
        { isClicked ? (
            <CtaButton
                onClick={ () => {
                  setLoading(true);
                  void printLabel(item).finally(() => setLoading(false));
                } }
                disabled={ !isLabelReady || loading }
            >
              { 'Print Shipping Labels' } { (!isLabelReady || loading) &&
                <CircularProgress size={ 20 } color="inherit" sx={ { ml: 1 } } /> }
            </CtaButton>
          ) : <CtaButton onClick={ onclick }>Prepare for pickup</CtaButton>
        }
      </StyledStack>
  );
};

export default PickupStep;
