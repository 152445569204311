import { useEffect } from 'react';
import MyItemsList from 'src/views/MyItemsList';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchItems, getItems } from 'src/store/itemsSlice';
import FAQ from 'src/components/infoSections/faq';
import MoreToSellSection from 'src/views/MoreToSellSection';
import { Stack } from '@worthy-npm/worthy-common-ui-components';
import { MyItemQuestions } from 'src/data/MyItemFaqs';
import Calendly from 'src/helpers/calendly.ts';
import { motion } from 'framer-motion';

const MyItems = () => {
    const dispatch = useAppDispatch();
    const itemList = useAppSelector(getItems);

    useEffect(() => {
        document.title = 'My Items';

        !itemList.length && dispatch(fetchItems());
    }, [dispatch, itemList.length]);


    useEffect(() => {
        Calendly.loadCalendly();

        return () => {
            Calendly.removeCalendly();
        };
    }, []);

    return (
        <motion.div layoutId="shared">
            <MyItemsList items={itemList} />
            {
                itemList.length !== 0 && (
                    <Stack
                        m={2}
                        alignItems={'center'}
                    >
                        <MoreToSellSection />
                    </Stack>
                )
            }
            <FAQ questions={MyItemQuestions} />
        </motion.div>
    );
};

export default MyItems;
