import { useEffect, useMemo, useRef, useState } from 'react';
import HIW from 'src/components/infoSections/hiw.tsx';
import FAQ from 'src/components/infoSections/faq.tsx';
import ScheduleCall from 'src/components/scheduleCall/scheduleCall.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import SchedulingCTA from 'src/components/SchedulingCTA';
import {
    fetchItems,
    getItems,
    selectError,
    selectLoading, setIsAllShippingPending,
} from 'src/store/itemsSlice';
import {
    isSchedulingOpen,
    resetShippingState,
    setLocations,
    setOpenScheduling,
    setScheduledItems,
    setSchedulingMainItem,
} from 'src/store/schedulingSlice';
import { usePreSchedulingItems } from 'src/hooks/usePreSchedulingItems.ts';
import SchedulingList from 'src/views/SchedulingList';
import { ItemGroups } from 'src/constants/itemCard.constants.tsx';
import NewItemSubmit from 'src/components/newItemSubmit';
import { HIWForSchedulingPropsData } from 'src/data/HIWForSchecduling';
import { Box, Unstable_Grid2 as Grid, styled } from '@worthy-npm/worthy-common-ui-components';
import Survey from 'src/components/survey/survey.tsx';
import { Item } from 'src/types/item.ts';
import { LoadingDiamondImg } from 'src/components/loader.tsx';
import ItemsEmptyPage from 'src/views/ItemsEmpty.tsx';
import { useDesktopVersion } from 'src/helpers/deviceSize.ts';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp.tsx';
import { SchedulingQuestions } from 'src/data/SchedulingFaqs';
import Calendly from 'src/helpers/calendly.ts';
import Ga from 'src/services/ga.service/ga.ts';
import { motion } from 'framer-motion';
// import { items } from 'src/data/Item';

const SchedulingContainer = styled(Box)(({ theme }) => ({
    minHeight: 457,
    marginTop: 48,
    border: `1px solid ${ theme.palette.divider }`,
    borderRadius: theme.shape.borderRadius,
}));

const Shipping = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const error = useAppSelector(selectError);
    const itemList = useAppSelector(getItems);
    // const itemList = items;
    const openSchedulingPopup = useAppSelector(isSchedulingOpen);
    const [ isSurveyOpen, setIsSurveyOpen ] = useState(false);
    const [ surveyItem, setSurveyItem ] = useState<Item>();
    const deselectedItems = useRef<Set<string>>(new Set());

    const isPC = useDesktopVersion();

    const groupedItemsMemo = usePreSchedulingItems(itemList);
    const pendingItems = useMemo(
        () => groupedItemsMemo[ItemGroups.Pending] || [],
        [ groupedItemsMemo ],
    );
    const validItems = useMemo(
        () => groupedItemsMemo[ItemGroups.Valid] || [],
        [ groupedItemsMemo ],
    );
    const [ selectedItems, setSelectedItems ] = useState<Item[]>(validItems);

    const [ allPending, setAllPending ] = useState(
        (validItems || []).length === 0 &&
        (pendingItems || []).length > 0,
    );

    useEffect(() => {
        Ga.SendActionOpen({
            actionedObject: 'sellerDashboardScreen',
            actionedValue: 'shipping',
            location1: 'sellerDashboard',
            location2: 'shippingTab',
            ...(isPC && {
                actionedObject2: 'schedulingFunnel',
                actionedValue2: 'shipmentMethodSelectionStepView',
            }),
        });
        dispatch(resetShippingState());
    }, []);

    useEffect(() => {
        Calendly.loadCalendly();

        return () => {
            Calendly.removeCalendly();
        };
    }, []);

    useEffect(() => {
        document.title = 'Scheduling';
        !itemList.length && dispatch(fetchItems());
    }, [ dispatch, itemList.length ]);

    useEffect(() => {
        setAllPending(
            (validItems || []).length === 0 &&
            (pendingItems || []).length > 0,
        );
    }, [ validItems, pendingItems ]);

    useEffect(() => {
        dispatch(setIsAllShippingPending(allPending));

        return () => {
            dispatch(setIsAllShippingPending(false));
        };
    }, [ allPending ]);

    useEffect(() => {
        setSelectedItems((prevSelectedItems) => {
            const validSelectedItems = prevSelectedItems.filter((item) =>
                validItems.some(validItem => validItem.publicId === item.publicId),
            );
            const prevSelectedIds = new Set(validSelectedItems.map((item) => item.publicId));
            const newItems = validItems.filter(
                (item) =>
                    !deselectedItems.current.has(item.publicId) && !prevSelectedIds.has(item.publicId) && !item.publicId.includes('temp'),
            );
            return [ ...validSelectedItems, ...newItems ];
        });
    }, [ validItems ]);


    useEffect(() => {
        dispatch(setSchedulingMainItem(selectedItems));
        dispatch(setScheduledItems(selectedItems.map(item => (item.publicId))));
    }, [ selectedItems ]);

    useEffect(() => {
        const searchUl = new URLSearchParams(location.search);
        const showItemSurvey = searchUl.get('survey');
        const searchItemId = searchUl.get('itemId');
        if (itemList.length && showItemSurvey && searchItemId) {
            const itemToSurvey = itemList.find((item) => item.publicId === searchItemId);
            if (itemToSurvey) {
                setSurveyItem(itemToSurvey);
                setIsSurveyOpen(true);
            }
        }
    }, [ itemList ]);

    const onSurveyClose = () => {
        setIsSurveyOpen(false);
        window.history.replaceState({}, '', `${ location.origin }${ location.pathname }`);
    };

    const handleCheckboxChange = (item: Item, isChecked: boolean) => {
        if (isChecked) {
            deselectedItems.current.delete(item.publicId);
        } else {
            deselectedItems.current.add(item.publicId);
        }

        setSelectedItems((prevSelectedItems) => {
            if (isChecked) {
                return prevSelectedItems.some((stateItem) => stateItem.publicId === item.publicId)
                    ? prevSelectedItems
                    : [ ...prevSelectedItems, item ];
            } else {
                return prevSelectedItems.filter(
                    (stateItem) => stateItem.publicId !== item.publicId,
                );
            }
        });
        dispatch(setLocations([]));
    };

    const handleScheduleClick = () => {
        dispatch(setSchedulingMainItem(selectedItems));
        dispatch(setScheduledItems(selectedItems.map(item => (item.publicId))));
        dispatch(setLocations([]));
        dispatch(setOpenScheduling(true as boolean));
        Ga.SendActionOpen({
            actionedObject2: 'schedulingFunnel',
            actionedValue2: 'shipmentMethodSelectionStepView',
            location1: 'sellerDashboard',
            location2: 'shippingScreen',
            location3: 'shippingOptionsComponent',
            location4: 'shippingMethodSelection',
        });
    };

    if (error) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="100px"
            >
                <LoadingDiamondImg />
            </Box>
        );
    }

    const getItemsComponent = () => {
        if (loading) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="100px"
                >
                    <LoadingDiamondImg />
                </Box>
            );
        }

        if (pendingItems.length === 0 && validItems.length === 0) {
            return (
                <ItemsEmptyPage
                    title="You have no items ready to ship..."
                    body="Tap below to add a new item and start selling."
                />
            );
        }

        return (
            <Grid
                container
                justifyContent="center"
                width="100%"
                maxWidth="1000px"
                margin="0 auto"
                columnSpacing={ { md: 6 } }
            >
                <Grid xs={ 12 } md={ 7 } justifyContent="center">
                    <SchedulingList
                        items={ itemList }
                        onCheckboxChange={ handleCheckboxChange }
                    />
                    { validItems && <NewItemSubmit /> }
                </Grid>
                {
                    isPC && (
                        <Grid xs={ 5 } justifyContent="center">
                            <SchedulingContainer>
                                <SchedulingPopUp isPC={ isPC } />
                            </SchedulingContainer>
                            {
                                !(allPending || !selectedItems.length) && (
                                    <ScheduleCall
                                        itemList={ validItems }
                                    />
                                )
                            }
                        </Grid>
                    )
                }
            </Grid>
        );
    };

    return (
        <motion.div layoutId="shared">
            { getItemsComponent() }
            <Box p={ 2 } marginTop={ { xs: 1, md: 0 } }>
                <HIW { ...HIWForSchedulingPropsData } />
            </Box>
            {
                !isPC && (
                    <ScheduleCall
                        itemList={ validItems }
                    />
                )
            }

            <FAQ questions={ SchedulingQuestions } />
            { !isPC && !openSchedulingPopup &&
                (<SchedulingCTA
                    disabled={ false }
                    itemsCount={ selectedItems.length }
                    onBtnClick={ handleScheduleClick }
                />)
            }
            { isSurveyOpen && surveyItem && (
                <Survey
                    open={ isSurveyOpen }
                    onClose={ onSurveyClose }
                    item={ surveyItem }
                />
            ) }
        </motion.div>
    );
};

export default Shipping;
