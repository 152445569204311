import React from 'react';
// import { useState } from "react";
import { Stack } from '@worthy-npm/worthy-common-ui-components';
// import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@worthy-npm/worthy-common-ui-components';
import ItemCard from 'src/components/ItemCard';
import { abortedStatuses, shippingStates, WorkflowStatus } from 'src/constants/item.constants';
import ItemsEmptyPage from './ItemsEmpty';
import _ from 'lodash';
import { Item } from 'src/types/item.ts';
// import {items as dummyItems
// } from 'src/data/Item';
import { postScheduleItems } from 'src/helpers/item';

interface MyItemsProps {
    items: Item[];
}


const grpoupItems = (items: Item[]) => {
  const groupedItems = items.reduce(
      (acc, item) => {
          let key = item.publicId;
          if (shippingStates.includes(item.status)) {
              key = `ShippingId:${item?.schedulingDetails?.shipment?.shipmentId}` || `PublicId:${item.publicId}`;
          } else if (abortedStatuses.includes(item.status)) {
              const existingGroup = acc[`Rejected:${item.status}`] || [];
              const hasRejectedAfterAuction = existingGroup.some(
                  (i) => i.status === WorkflowStatus.REJECTED_AFTER_AUCTION
              );
              key = item?.schedulingDetails?.shipment?.shipmentId
                  ? `ShippingId:${item.schedulingDetails.shipment.shipmentId}`
                  : hasRejectedAfterAuction || item.status === WorkflowStatus.REJECTED_AFTER_AUCTION
                      ? `Rejected:${WorkflowStatus.REJECTED_AFTER_AUCTION}`
                      : `Rejected:${item.status}`;
          }
          const group = _.get(acc, key, [] as Item[]);
          group.push(item);
          _.set(acc, key, group);
          return acc;
      },
      {} as Record<string, Item[]>,
  );
  return sortItemsGroup(Object.values(groupedItems));
};

const maxCreatedTime = (items: Item[]) => {
  return _.max(items.map((item: Item) => new Date(item.createdAt).getTime())) || 0;
}

const sortItemsGroup = (itemsGroup: Item[][]) => {
    itemsGroup.sort((a, b) => {
        return maxCreatedTime(b) - maxCreatedTime(a) ;
    })
    return itemsGroup;
}

const MyItems: React.FC<MyItemsProps> = ({ items }) => {
  // const [selectedOption, setSelectedOption] = useState('default');

  // const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedOption(event.target.value);
  // };

  const relevantItems = postScheduleItems(items);
  const groupedItems = grpoupItems(relevantItems);

  // const groupedItemsDummy = grpoupItems(postScheduleItems(dummyItems))

    return (
      <>
        {/* <Box marginBottom={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Options</FormLabel>
            <RadioGroup row aria-label="options" name="options" value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="loadDummyData" control={<Radio />} label="Load Dummy Items Data" />
              <FormControlLabel value="forceNoItems" control={<Radio />} label="Force No Items Screen" />
              <FormControlLabel value="forceNoRelevantItems" control={<Radio />} label="Force No Relevant Items Screen" />
              <FormControlLabel value="default" control={<Radio />} label="Default" />
            </RadioGroup>
          </FormControl>
        </Box>
        { (selectedOption === "default" && items.length === 0) || selectedOption === "forceNoItems" ? (
        <ItemsEmptyPage
          title="You have no items"
          body="Go to Scheduling tab or tap here to add them"
        />
    ) : (selectedOption === "default" && relevantItems.length === 0) || selectedOption === "forceNoRelevantItems" ? (
        <ItemsEmptyPage pendingScheduling />
    ) : (
        <Stack
            alignItems={'center'}
            gap={{sm: 1.5, md:3}}
            p={2}
        >
            {Object.values(  selectedOption === "default" ? groupedItems : groupedItemsDummy).map((bundle) => (
                <ItemCard
                    key={bundle[0].publicId}
                    items={bundle}
                />
            ))}
        </Stack>
    )} */}
    {
      items.length === 0 ? (<ItemsEmptyPage
        title="You do not have any active items"
        body="Start selling by submitting your item below."
      />) : relevantItems.length === 0 ? (<ItemsEmptyPage pendingScheduling />) : (
        <Stack
          alignItems={'center'}
          gap={{sm: 1.5, md:3}}
          p={2}
        >
          {groupedItems.map((bundle) => (
            <ItemCard
              key={bundle[0].publicId}
              items={bundle}
            />
          ))}
        </Stack>
      )
    }
      </>
    );
  };

export default MyItems;
